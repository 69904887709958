import { createSlice } from "@reduxjs/toolkit";
import { GALLERIES_FAIL, GALLERIES_REQUEST, GALLERIES_SUCCESS } from "../../constants/galleries";

const galleriesSlice = createSlice({
  name: 'galleries',
  initialState: {
    galleries: {
      loading: false,
      error: {
        message: ''
      },
      data: [{
        _id: '',
        name: '',
        images: []
      }]
    }
  },
  reducers: {
    galleriesFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case GALLERIES_REQUEST:
          state.galleries.loading = true;
          state.galleries.error.message = '';
          break;
        case GALLERIES_SUCCESS:
          state.galleries.loading = false;
          state.galleries.data = payload;
          state.galleries.error.message = '';
          break;
        case GALLERIES_FAIL:
          state.galleries.loading = false;
          state.galleries.error = payload;
          break;
      }
    },
    addGalleryController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case GALLERIES_REQUEST:
          state.galleries.loading = true;
          state.galleries.error.message = '';
          break;
        case GALLERIES_SUCCESS:
          state.galleries.loading = false;
          state.galleries.error.message = '';
          break;
        case GALLERIES_FAIL:
          state.galleries.loading = false;
          state.galleries.error = payload;
          break;
      
      }
    }
  }
});

export const galleriesActions = galleriesSlice.actions;
export default galleriesSlice;