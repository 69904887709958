
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminAddPuppy from "../../../components/Containers/admin/AdminAddPuppy/AdminAddPuppy";
import AdminPuppyContainer from "../../../components/Containers/admin/AdminPuppyContainer/AdminPuppyContainer";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { RootState } from "../../../store";
import { puppiesFetch } from "../../../store/puppies/puppies-actions";
import classes from './AdminPuppiesPage.module.scss';

const AdminPuppiesPage = () => {
  const dispatch = useDispatch();

  
  useEffect(() => {
    dispatch(puppiesFetch());
    window.scrollTo({ top: 250, behavior: 'smooth' });
  }, [dispatch]);

  const puppies = useSelector((state: RootState) => state.puppies.puppies);
  const { loading, error, data } = puppies;
  
  const renderPuppies = data.map((puppy) => (
    <AdminPuppyContainer
      key={puppy._id}
      _id={puppy._id}
      name={puppy.name}
      description={puppy.description}
      imageUrl={puppy.imageUrl}
    />
  ))
  

  return (
    <AdminLayout loading={loading} error={error} >
      <section className={classes.wrapper}>
        <div className={classes.container}>
          <h1>Dodaj nowego szczeniaczka:</h1>
          <div className={classes['dog-container']}>
            <AdminAddPuppy />
          </div>
        </div>

        <div className={classes.container}>
          <h1>Edytuj istniejącego szczeniaczka:</h1>
          <div className={classes['dog-container']}>
            {renderPuppies}
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default AdminPuppiesPage;
