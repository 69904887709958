import { useNavigate } from 'react-router';
import classes from './BackButton.module.scss';

interface IProps {
  title: string;
  fontSize: number;
}

const BackButton = ({ title, fontSize }: IProps) => {
  const navigate = useNavigate();
  const backToPreviousPage = () => {
    navigate(-1);
  }

  return (
    <button
      className={classes['back-button']}
      onClick={backToPreviousPage}
      style={
        {fontSize: `${fontSize}rem`}
      }
    >
      {title}
    </button>
  );
};

export default BackButton;
