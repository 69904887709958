import { Link } from 'react-router-dom';
import classes from './AdminElement.module.scss';

interface IProps {
  linkTo: string;
  title: string
}

const AdminElement = ({linkTo, title}: IProps) => {
  return (
    <Link to={linkTo} className={classes.container}>
      <h2>Edytuj:</h2>
      <h1>"{title}"</h1>
    </Link>
  );
};

export default AdminElement;
