import axios from "axios";
import { BACKEND_URL } from "../../config/backendData";
import { PUPPIES_FAIL, PUPPIES_REQUEST, PUPPIES_SUCCESS } from "../../constants/puppies";
import { puppiesActions } from "./puppies-slice";

export const puppiesFetch = () => async (dispatch: any) => {
  dispatch(puppiesActions.puppiesFetchController({
    type: PUPPIES_REQUEST
  }));

  const getDogs = async () => {
    const data = await axios.get(`${BACKEND_URL}/puppy`);
    return data;
  }

  try {
    const { data } = await getDogs();
    
    dispatch(puppiesActions.puppiesFetchController({
      type: PUPPIES_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(puppiesActions.puppiesFetchController({
      type: PUPPIES_FAIL,
      payload: {message: 'Pobieranie szczeniaczków nie powiodło się.'}
    }))
  }
}

//GET single puppy
export const puppyFetch = (_id: string) => async (dispatch: any) => {
  dispatch(puppiesActions.singlePuppyFetchController({
    type: PUPPIES_REQUEST
  }));

  const getSinglePuppy = async () => {
    const data = await axios.get(`${BACKEND_URL}/puppy/${_id}`);
    return data;
  }

  try {
    const { data } = await getSinglePuppy();
    
    dispatch(puppiesActions.singlePuppyFetchController({
      type: PUPPIES_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(puppiesActions.singlePuppyFetchController({
      type: PUPPIES_FAIL,
      payload: {message: 'Pobieranie szczeniaczka nie powiodło się.'}
    }))
  }
}

//EDIT puppy data
export const editPuppy = (_id: string, name: string, description: string, galleryUrl: string, token: string) => async (dispatch: any) => {
  dispatch(puppiesActions.editPuppyFetchController({
    type: PUPPIES_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const editedPuppy = {
    name,
    description,
    galleryUrl
  }

  const editPuppy = async () => {
    const data = await axios.patch(`${BACKEND_URL}/puppy/data/${_id}`, editedPuppy, config);
    return data;
  }

  try {
    const { data } = await editPuppy();
    
    dispatch(puppiesActions.editPuppyFetchController({
      type: PUPPIES_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(puppiesActions.editPuppyFetchController({
      type: PUPPIES_FAIL,
      payload: {message: 'Edycja szczeniaczka nie powiodła się.'}
    }))
  }
}

//EDIT puppy Image
export const editImagePuppy = (_id: string, image: File, token: string) => async (dispatch: any) => {
  dispatch(puppiesActions.editPuppyFetchController({
    type: PUPPIES_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const formData = new FormData();
  formData.append('image', image, image.name);

  const editPuppyImage = async () => {
    const data = await axios.patch(`${BACKEND_URL}/puppy/image/${_id}`, formData, config);
    return data;
  }

  try {
    const { data } = await editPuppyImage();
    
    dispatch(puppiesActions.editPuppyFetchController({
      type: PUPPIES_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(puppiesActions.editPuppyFetchController({
      type: PUPPIES_FAIL,
      payload: {message: 'Edycja szczeniaczka nie powiodło się.'}
    }))
  }
}

//DELETE puppy
export const deletePuppy = (_id: string, token: string) => async (dispatch: any) => {
  dispatch(puppiesActions.deletePuppyController({
    type: PUPPIES_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const deleteSinglePuppy = async () => {
    const data = await axios.delete(`${BACKEND_URL}/puppy/${_id}`, config);
    return data;
  }

  try {
    await deleteSinglePuppy();
    
    dispatch(puppiesActions.deletePuppyController({
      type: PUPPIES_SUCCESS
    }));
    
  } catch (error) {
    dispatch(puppiesActions.deletePuppyController({
      type: PUPPIES_FAIL,
      payload: {message: 'Usunięcie szczeniaczka nie powiodło się.'}
    }))
  }
}

//ADD puppy
export const addPuppy = (name: string, description: string, galleryUrl: string, image: File, token: string) => async (dispatch: any) => {
  dispatch(puppiesActions.addPuppyController({
    type: PUPPIES_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const formData = new FormData();
  formData.append('image', image, image.name);
  formData.append('name', name);
  formData.append('description', description);
  formData.append('galleryUrl', galleryUrl);

  const addPuppy = async () => {
    const data = await axios.post(`${BACKEND_URL}/puppy`, formData, config);
    return data;
  }

  try {
    await addPuppy();
    
    dispatch(puppiesActions.addPuppyController({
      type: PUPPIES_SUCCESS
    }))
    dispatch(puppiesFetch());
  } catch (error) {
    dispatch(puppiesActions.addPuppyController({
      type: PUPPIES_FAIL,
      payload: {message: 'Dodanie szczeniaczka nie powiodło się.'}
    }))
  }
}