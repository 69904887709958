import classes from './NavButton.module.scss';
import { NavLink } from 'react-router-dom';
import { INavButton } from '../../../interfaces/INavButton';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';



const NavButton = ({ title, route }: INavButton) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  
  const isMobile = useSelector((state: RootState) => state.userWindow.isMobile);

  const hoverHandler = () => {
    setIsHover(!isHover);
  }

  const clickHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const classController = (isActive: boolean) => {
    let NavClass = classes['nav-button'];
    const NavActiveClass = `${classes['nav-button--active']} ${classes['nav-button']}`

    if (isHover && !isMobile) {
      NavClass = `${classes['nav-button--faded']} ${classes['nav-button']}`
    }

    return isActive ? NavActiveClass : NavClass;
  }

  return (
    <NavLink
      className={(navData) => classController(navData.isActive)}
      onMouseEnter={hoverHandler}
      onMouseLeave={hoverHandler}
      onClick={clickHandler}
      to={route}
    >
      {title}
    </NavLink>
  );
};

export default NavButton;
