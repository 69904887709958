import React, { useState } from 'react';
import classes from './TextInput.module.scss';

interface IProps {
  value: string;
  isValid: boolean;
}

const TextInput = React.forwardRef<HTMLInputElement, IProps>(({ value, isValid }, ref) => {
  const [inputValue, setInputValue] = useState(value);

  const passwordClass = isValid
    ? classes['text-input']
    : `${classes['text-input']} ${classes['text-input--invalid']}`;
  
  const updateInputValue = (event: any) => {
    setInputValue(event.target.value);
  }

  return (
    <>
      <input ref={ref} type='text' value={inputValue} onChange={updateInputValue} className={passwordClass} />
    </>
  );
});

export default TextInput;
