import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BACKEND_URL } from '../../config/backendData';
import { RootState } from '../../store';
import { changeImage, toggleFullscreenImage } from '../../store/fullscreenImage/fullscreenImage-actions';
import classes from './FullscreenImage.module.scss';

const FullscreenImage = () => {
  const dispatch = useDispatch();
  const [isImageHover, setIsImageHover] = useState<boolean>(false);

  const activeAlbum = useSelector((state: RootState) => state.fullscreenImage);
  const { images, actualImage } = activeAlbum;
  const isMobile = useSelector((state: RootState) => state.userWindow.isMobile);

  const imageHoverHandler = () => {
    setIsImageHover(!isImageHover);
  }

  const toggleFullscreenImageHandler = useCallback(() => {
    dispatch(toggleFullscreenImage());
  }, [dispatch]);

  const changeImageHandler = useCallback((isIncreasing: boolean) => {
    dispatch(changeImage(images, actualImage, isIncreasing));
  }, [dispatch, images, actualImage]);

  useEffect(() => {
    const handlePhotoByKey = (event: any) => {
      event.preventDefault();
      if(event.keyCode === 27) {
        toggleFullscreenImageHandler();
      }
      if(event.keyCode === 39) {
        changeImageHandler(true)
      } else if(event.keyCode === 37) {
        changeImageHandler(false)
      }
    }

    window.addEventListener("keydown", handlePhotoByKey);

    return () => {
      window.removeEventListener("keydown", handlePhotoByKey);
    }
  }, [actualImage, changeImageHandler, toggleFullscreenImageHandler])

  const actualFullscreenImageUrl = `${BACKEND_URL}/${actualImage}`;
  const buttonClass = isMobile
    ? classes.button
    : isImageHover && !isMobile
    ? `${classes.button} ${classes['button--active']}`
    : classes.button;

  return (
    <div className={classes.container} onMouseEnter={imageHoverHandler} onMouseLeave={imageHoverHandler}>
      <button className={classes['close-button']} onClick={toggleFullscreenImageHandler} />
      <button className={`${buttonClass} ${classes['left-button']}`} onClick={changeImageHandler.bind(this, false)} />
      <img src={actualFullscreenImageUrl} alt={actualImage} className={classes.image} />
      <button className={`${buttonClass} ${classes['right-button']}`} onClick={changeImageHandler.bind(this, true)} />
    </div>
  );
};

export default FullscreenImage;
