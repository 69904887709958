
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminAddGallery from "../../../components/Containers/admin/AdminAddGallery/AdminAddGallery";
import GalleryContainer from "../../../components/GalleryContainer/GalleryContainer";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { RootState } from "../../../store";
import { galleriesFetch } from "../../../store/galleries/galleries-actions";
import classes from './AdminGalleryPage.module.scss';

const AdminGalleryPage = () => {
  const dispatch = useDispatch();

  const galleries = useSelector((state: RootState) => state.galleries.galleries);
  const { loading, error, data } = galleries;
  
  useEffect(() => {
    dispatch(galleriesFetch());
    window.scrollTo({ top: 250, behavior: 'smooth' });
  }, [dispatch]);

  const renderAdminGalleries = data.map(gallery => (
    <GalleryContainer
      key={gallery._id}
      _id={gallery._id}
      name={gallery.name}
      images={gallery.images}
      isAdminPanel={true}
    />
  ));

  return (
    <AdminLayout loading={loading} error={error} >
      <section className={classes.wrapper}>
        <div className={classes.container}>
          <h1>Dodaj nową galeię:</h1>
          <AdminAddGallery />
        </div>

        <div className={classes.container}>
          <h1>Edytuj istniejące galerie:</h1>
          <p>Kliknij w wybrany obrazek aby przejść do panelu edycji</p>
          <div className={classes['recommend-container']}>
            {renderAdminGalleries}
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default AdminGalleryPage;
