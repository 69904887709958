import { BACKEND_URL } from '../../../../config/backendData';
import { IRecommend } from '../../../../interfaces/IRecommend';
import classes from './AdminRecommend.module.scss';

import dogFootprintImage from '../../../../images/mainPage/dogFootprint.png';
import { useNavigate } from 'react-router';
import EditIcon from '../../../EditIcon/EditIcon';

const AdminRecommend = ({ _id, name, recommendUrl, imageUrl }: IRecommend) => {
  const navigate = useNavigate();
  
  const clickHandler = () => {
    navigate(`/admin/polecamy/${_id}`);
  }

  if (!recommendUrl.includes('http://')) {
    recommendUrl = `http://${recommendUrl}`;
  }

  const minImageUrl = imageUrl ? `${BACKEND_URL}/${imageUrl}` : dogFootprintImage;

  return (
    <button className={classes['recommend-container']} onClick={clickHandler}>
      <div className={classes['recommend-image']} style={{ backgroundImage: `url('${minImageUrl}')` }} >
        <EditIcon />
      </div>
      <h3 className={classes['recommend-name']}>{name}</h3>
      <p>(kliknij okno aby edytować)</p>
    </button>
  );
};

export default AdminRecommend;
