import React from 'react';
import classes from './PasswordInput.module.scss';

interface IProps {
  title: string;
  isRequired: boolean;
  isValid: boolean;
}

const PasswordInput = React.forwardRef<HTMLInputElement, IProps>(({ title, isRequired, isValid }, ref) => {
  
  const passwordClass = isValid
    ? classes['password-input']
    : `${classes['password-input']} ${classes['password-input--invalid']}`

  return (
    <>
      {
        isRequired
        ? <input ref={ref} type='password' placeholder={title} required className={passwordClass} />
        : <input ref={ref} type='password' placeholder={title} className={passwordClass} />
      }
    </>
  );
});

export default PasswordInput;
