import axios from "axios";
import { BACKEND_URL } from "../../config/backendData";
import { ADMIN_FAIL, ADMIN_REQUEST, ADMIN_SUCCESS } from "../../constants/admin";
import { adminActions } from "./admin-slice";

//LOGIN
export const loginAdmin = (email: string, password: string) => async (dispatch: any) => {
  dispatch(adminActions.loginAdmin({
    type: ADMIN_REQUEST
  }));

  console.log('login');

  const login = async () => {
    const admin = await axios.post(`${BACKEND_URL}/user/login`, {
      email,
      password
    });

    return admin;
  }

  try {
    const admin = await login();
    const { token, userData } = admin.data;
    
    dispatch(adminActions.loginAdmin({
      type: ADMIN_SUCCESS,
      token,
      payload: userData
    }));
    
    
  } catch (error) {
    dispatch(adminActions.loginAdmin({
      type: ADMIN_FAIL,
      payload: {message: 'Logowanie nie powiodło się.'}
    }))
  }
}

//GET
export const getAdmin = (token: string) => async (dispatch: any) => {
  dispatch(adminActions.getAdmin({
    type: ADMIN_REQUEST
  }));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const getAdmin = async () => {
    const data = await axios.get(`${BACKEND_URL}/user`, config);
    return data
  }

  try {
    const admin = await getAdmin();
    
    dispatch(adminActions.getAdmin({
      type: ADMIN_SUCCESS,
      payload: admin.data
    }));
  } catch (error) {
    dispatch(adminActions.getAdmin({
      type: ADMIN_FAIL,
      payload: { message: 'Pobieranie użytkownika nie powiodło się.' }
    }));
  }
}

//LOGOUT
export const logoutAdmin = (token: string) => async (dispatch: any) => {
  dispatch(adminActions.logoutAdmin({
    type: ADMIN_REQUEST
  }));
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const logoutAdminRequest = async () => {
    await axios.post(`${BACKEND_URL}/user/logout`, {}, config);
  }

  try {
    await logoutAdminRequest();
    
    dispatch(adminActions.logoutAdmin({
      type: ADMIN_SUCCESS
    }));
  } catch (error) {
    dispatch(adminActions.logoutAdmin({
      type: ADMIN_FAIL,
      payload: { message: 'Wylogowanie użytkownika nie powiodło się.' }
    }));
  }
}

//LOGOUT ALL
export const logoutAllAdmin = (token: string) => async (dispatch: any) => {
  dispatch(adminActions.logoutAdmin({
    type: ADMIN_REQUEST
  }));
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const logoutAllAdminRequest = async () => {
    await axios.post(`${BACKEND_URL}/user/logoutall`, {}, config);
  }

  try {
    await logoutAllAdminRequest();
    
    dispatch(adminActions.logoutAdmin({
      type: ADMIN_SUCCESS
    }));
  } catch (error) {
    dispatch(adminActions.logoutAdmin({
      type: ADMIN_FAIL,
      payload: { message: 'Wylogowanie użytkownika nie powiodło się.' }
    }));
  }
}

//CHANGE PASSWORD
export const changePasswordAdmin = (password: string, newPassword: string, repeatNewPassword: string, token: string) => async (dispatch: any) => {
  if (newPassword !== repeatNewPassword) {
    dispatch(adminActions.changePassword({
      type: ADMIN_FAIL,
      payload: { message: 'Hasła w tych polach muszą być identyczne.' }
    }));

    return;
  }
  
  dispatch(adminActions.changePassword({
    type: ADMIN_REQUEST
  }));
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const user = {
    enteredPassword: password,
    newPassword: newPassword
  }
  
  const changePasswordAdminRequest = async () => {
    await axios.patch(`${BACKEND_URL}/user/edit`, user, config);
  }

  try {
    await changePasswordAdminRequest();
    
    dispatch(adminActions.changePassword({
      type: ADMIN_SUCCESS
    }));
    dispatch(logoutAllAdmin(token));
  } catch (error) {
    dispatch(adminActions.changePassword({
      type: ADMIN_FAIL,
      payload: { message: 'Zmiana hasła nie powiodła się.' }
    }));
  }
}