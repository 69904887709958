import classes from './ErrorBox.module.scss';

interface IProps {
  errorMessage: string
} 

const ErrorBox = ({errorMessage}: IProps) => {
  return (
    <div className={classes['error-box']}>
      <div className={classes['error-icon']} />
      <h1>{errorMessage}</h1>
    </div>
  );
};

export default ErrorBox;
