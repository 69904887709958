import classes from './SocialMediaButton.module.scss';

interface IProps {
  name: string;
  url: string;
  image: string;
}

const SocialMediaButton = ({ name, url, image }: IProps) => {
  if (!url.includes('http://')) {
    url = `http://${url}`;
  }


  return (
    <a rel="noreferrer" target='_blank' href={url} className={classes.container} title={name}>
      <div className={classes.image} style={{backgroundImage: `url('${image}')`}} />
    </a>
  );
};

export default SocialMediaButton;
