import { Link } from 'react-router-dom';
import { BACKEND_URL } from '../../config/backendData';
import { IDog } from '../../interfaces/IDog';
import classes from './Dog.module.scss';


const Dog = ({_id, name, description, imageUrl, galleryUrl}: IDog) => {
  return (
    <div className={classes['dog-container']}>
      <div className={classes['dog-image-container']}>
        <div className={classes['dog-image']} style={{backgroundImage: `url('${BACKEND_URL}/${imageUrl}')`}}>
        </div>
        {galleryUrl && 
          <Link className={classes['gallery-link']} to={`/galeria/${galleryUrl}`}>WIĘCEJ ZDJĘĆ</Link>
        }
      </div>
      <div className={classes['dog-content']}>
        <h1>{name}</h1>
        <div>{description}</div>
      </div>
    </div>
  );
};

export default Dog;
