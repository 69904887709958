
import Cookies from "js-cookie";
import { SyntheticEvent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../../../components/Buttons/BackButton/BackButton";
import SubmitButton from "../../../components/Buttons/SubmitButton/SubmitButton";
import MainForm from "../../../components/Forms/MainForm/MainForm";
import NumberInput from "../../../components/Inputs/NumberInput/NumberInput";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { ADMIN_TOKEN_COOKIE } from "../../../constants/admin";
import { RootState } from "../../../store";
import { contactFetch, editContact } from "../../../store/contact/contact-actions";
import classes from './AdminEditContact.module.scss';

const AdminEditContact = () => {
  const dispatch = useDispatch();

  const fullNameRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const facebookRef = useRef<HTMLInputElement>(null);
  const instagramRef = useRef<HTMLInputElement>(null);
  
  
  useEffect(() => {
    dispatch(contactFetch());
    window.scrollTo({ top: 400, behavior: 'smooth' });
  }, [dispatch]);


  const contactData = useSelector((state: RootState) => state.contact.contactData);
  const { loading, error, contact } = contactData;

  const submitEditDataHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const fullNameValue = fullNameRef.current!.value;
    let phoneNumberValue = phoneNumberRef.current!.value;
    const cityValue = cityRef.current!.value;
    const emailValue = emailRef.current!.value;
    const facebookValue = facebookRef.current!.value;
    const instagramValue = instagramRef.current!.value;

    if (!+phoneNumberValue) {
      phoneNumberValue = '0';
    }
    
    
    dispatch(editContact(
      fullNameValue,
      cityValue,
      +phoneNumberValue,
      emailValue,
      facebookValue,
      instagramValue,
      Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  return (
    <AdminLayout loading={loading} error={error} >
      <div className={classes.container}>
        <h1>Edytuj "Kontakt"</h1>
        <BackButton title="Wróć" fontSize={1.35} />

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitEditDataHandler}>
            <h3>Edytuj dane:</h3>

            <label>Imię i nazwisko:</label>
            <TextInput ref={fullNameRef} isValid={true} value={contact.fullName} />

            <label>Miasto:</label>
            <TextInput ref={cityRef} isValid={true} value={contact.city} />

            <label>Nr Telefonu:</label>
            <p>(wyłącznie liczby od 0 do 9 bez spacji)</p>
            <NumberInput ref={phoneNumberRef} value={contact.phoneNumber} />
            
            <label>Email:</label>
            <TextInput ref={emailRef} isValid={true} value={contact.email} />

            <label>Facebook:</label>
            <TextInput ref={facebookRef} isValid={true} value={contact.facebook} />

            <label>Instagram:</label>
            <TextInput ref={instagramRef} isValid={true} value={contact.instagram} />

            <SubmitButton title="ZAPISZ" fontSize={1.5} />
          </MainForm>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminEditContact;
