import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { RootState } from '../../store';
import { contactFetch } from '../../store/contact/contact-actions';
import classes from './ContactPage.module.scss';

import dogsImage from '../../images/contact/contactDogs.png';
import compassImage from '../../images/icons/compassIcon.svg';
import emailImage from '../../images/icons/emailIcon.svg';
import phoneImage from '../../images/icons/phoneIcon.svg';
import userImage from '../../images/icons/userIcon.svg';
import ContactField from '../../components/ContactField/ContactField';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';

import facebookIcon from '../../images/icons/facebookIcon.png';
import instagramIcon from '../../images/icons/instagramIcon.png';
import SocialMediaButton from '../../components/Buttons/SocialMediaButton/SocialMediaButton';

const ContactPage = () => {
  const dispatch = useDispatch();

  const contactData = useSelector((state: RootState) => state.contact.contactData);
  const { loading, error, contact } = contactData;
  const { fullName, city, phoneNumber, email, facebook, instagram } = contact;
  
  
  useEffect(() => {
    dispatch(contactFetch());
    
  }, [dispatch]);

  const socialMediaText = facebook || instagram ? 'Social Media:' : '';
  
  return (
    <MainLayout>
      <section className={classes['contact-container']}>
        { loading && 
        <div className={classes['status-container']}>
          <LoadingSpinner />
        </div>
        }

        { error.message &&
        <div className={classes['status-container']}>
          <ErrorBox errorMessage={error.message} />
        </div>
        }

        { contact && 
        <>
          <div className={classes['contact-data']}>
            <h1>KONTAKT</h1>
            <ContactField data={fullName} title='imie' imageUrl={userImage} />
            <ContactField data={city} title='miasto' imageUrl={compassImage} />
            <ContactField data={phoneNumber} title='telefon' imageUrl={phoneImage} />
            <ContactField data={email} title='email' imageUrl={emailImage} />
            <div className={classes['social-media-container']}>
              <span>{socialMediaText}</span>
              <div>
                {facebook && <SocialMediaButton url={facebook} image={facebookIcon} name='facebook' />}
                {instagram && <SocialMediaButton url={instagram} image={instagramIcon} name='instagram' />}
              </div>
            </div>
          </div>
          <img src={dogsImage} alt='child-of-love-contact' className={classes.dogs} />
        </>
        }


      </section>
    </MainLayout>
  );
};

export default ContactPage;
