import { BACKEND_URL } from '../../config/backendData';
import { IRecommend } from '../../interfaces/IRecommend';
import classes from './Recommend.module.scss';

import dogFootprintImage from '../../images/mainPage/dogFootprint.png';

const Recommend = ({ _id, name, recommendUrl, imageUrl }: IRecommend) => {

  if (recommendUrl.startsWith('www')) {
    recommendUrl = `http://${recommendUrl}`;
  }

  const minImageUrl = imageUrl ? `${BACKEND_URL}/${imageUrl}` : dogFootprintImage;
  
  return (
    <a rel="noreferrer" target='_blank' href={recommendUrl} className={classes['recommend-container']}>
      <div className={classes['recommend-image']} style={{backgroundImage: `url('${minImageUrl}')`}} />
      <h3 className={classes['recommend-name']}>{name}</h3>
    </a>
  );
};

export default Recommend;
