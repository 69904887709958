import classes from './CookiesInfo.module.scss';
import ReactDOM from 'react-dom';
import Backdrop from '../Backdrop/Backdrop';
import MainButton from '../Buttons/MainButton/MainButton';

interface IProps {
  onClick: () => void;
}

const CookiesInfo = ({onClick}: IProps) => {
  return ReactDOM.createPortal(
    <>
      <Backdrop onClick={onClick} />
      <div className={classes.cookies}>
        <h1>Cookies</h1>
        <span>Ta strona wykorzystuje i zapisuje pliki cookies. Są one używane w celu poprawnego działania strony, zgodnie z ustawieniami przeglądarki. Możesz je wyłączyć w ustawieniach przeglądarki. Korzystając ze strony akceptujesz pliki cookies.</span>
        <MainButton title='Akceptuj' onClick={onClick} fontSize={1.1}  />
      </div>
    </>,
    document.getElementById('cookies')!
  );
};

export default CookiesInfo;
