import { SyntheticEvent } from 'react';
import classes from './MainForm.module.scss';

interface IProps {
  onSubmit?: (event: SyntheticEvent) => any;
  children: any;
}

const MainForm = ({onSubmit, children}: IProps) => {
  return (
    <form className={classes.container} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default MainForm;
