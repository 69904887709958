import { useSelector } from "react-redux";
import AdminElement from "../../../components/Containers/admin/AdminElement/AdminElement";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { RootState } from "../../../store";
import classes from './AdminPage.module.scss';

const AdminPage = () => {
  const adminData = useSelector((state: RootState) => state.admin.adminData);
  const {loading, error } = adminData

  return (
    <AdminLayout loading={loading} error={error} >
      <div className={classes.container}>
        <h1>Panel Administratora</h1>
        <div className={classes['admin-element-container']}>
          <AdminElement linkTo='/admin/o-nas' title='O Nas' />
          <AdminElement linkTo='/admin/nasze-suki' title='Nasze Suki' />
          <AdminElement linkTo='/admin/szczenieta' title='Szczenięta' />
          <AdminElement linkTo='/admin/galeria' title='Galerie' />
          <AdminElement linkTo='/admin/polecamy' title='Polecamy' />
          <AdminElement linkTo='/admin/kontakt' title='Kontakt' />
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminPage;
