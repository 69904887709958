import axios from "axios";
import { ABOUT_ID, BACKEND_URL } from "../../config/backendData";
import { ABOUT_FAIL, ABOUT_REQUEST, ABOUT_SUCCESS } from "../../constants/about";
import { aboutActions } from "./about-slice";

//Get single about
export const aboutFetch = () => async (dispatch: any) => {
  dispatch(aboutActions.aboutFetchController({
    type: ABOUT_REQUEST
  }));

  const getAbout = async () => {
    const data = await axios.get(`${BACKEND_URL}/about/${ABOUT_ID}`);
    return data;
  }

  try {
    const { data } = await getAbout();
    
    dispatch(aboutActions.aboutFetchController({
      type: ABOUT_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(aboutActions.aboutFetchController({
      type: ABOUT_FAIL,
      payload: {message: 'Pobieranie danych nie powiodło się.'}
    }))
  }
}

//Edit about
export const editAbout = (title: string, text: string, token: string) => async (dispatch: any) => {
  dispatch(aboutActions.editAboutController({
    type: ABOUT_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const editedAbout = {
    title,
    text
  }

  const editAbout = async () => {
    const data = await axios.patch(`${BACKEND_URL}/about/data/${ABOUT_ID}`, editedAbout, config);
    return data;
  }

  try {
    const { data } = await editAbout();
    
    dispatch(aboutActions.editAboutController({
      type: ABOUT_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(aboutActions.editAboutController({
      type: ABOUT_FAIL,
      payload: {message: 'Edycja danych nie powiodła się.'}
    }))
  }
}

//Edit Image about
export const editImageAbout = (image: File, token: string) => async (dispatch: any) => {
  dispatch(aboutActions.editAboutController({
    type: ABOUT_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const formData = new FormData();
  formData.append('image', image, image.name);

  const editAbout = async () => {
    const data = await axios.patch(`${BACKEND_URL}/about/image/${ABOUT_ID}`, formData, config);
    return data;
  }

  try {
    const { data } = await editAbout();
    
    dispatch(aboutActions.editAboutController({
      type: ABOUT_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(aboutActions.editAboutController({
      type: ABOUT_FAIL,
      payload: {message: 'Edycja danych nie powiodła się.'}
    }))
  }
}

//Delete about image
export const deleteImageAbout = (token: string) => async (dispatch: any) => {
  dispatch(aboutActions.editAboutController({
    type: ABOUT_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const deleteImage = async () => {
    const data = await axios.delete(`${BACKEND_URL}/about/image/${ABOUT_ID}`, config);
    return data;
  }

  try {
    const { data } = await deleteImage();
    
    dispatch(aboutActions.editAboutController({
      type: ABOUT_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(aboutActions.editAboutController({
      type: ABOUT_FAIL,
      payload: {message: 'Usunięcie zdjęcia nie powiodło się.'}
    }))
  }
}