import { createSlice } from "@reduxjs/toolkit";
import { RECOMMEND_FAIL, RECOMMEND_REQUEST, RECOMMEND_SUCCESS } from "../../constants/recommend";

const recommendSlice = createSlice({
  name: 'recommend',
  initialState: {
    recommend: {
      loading: false,
      error: {
        message: ''
      },
      data: [{
        _id: '',
        name: '',
        recommendUrl: '',
        imageUrl: ''
      }]
    },
    singleRecommend: {
      loading: false,
      error: {
        message: ''
      },
      data: {
        _id: '',
        name: '',
        recommendUrl: '',
        imageUrl: ''
      }
    }
  },
  reducers: {
    recommendFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case RECOMMEND_REQUEST:
          state.recommend.loading = true;
          state.recommend.error.message = '';
          break;
        case RECOMMEND_SUCCESS:
          state.recommend.loading = false;
          state.recommend.data = payload;
          state.recommend.error.message = '';
          break;
        case RECOMMEND_FAIL:
          state.recommend.loading = false;
          state.recommend.error = payload;
          break;
      }
    },
    singleRecommendFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case RECOMMEND_REQUEST:
          state.singleRecommend.loading = true;
          state.singleRecommend.error.message = '';
          break;
        case RECOMMEND_SUCCESS:
          state.singleRecommend.loading = false;
          state.singleRecommend.data = payload;
          state.singleRecommend.error.message = '';
          break;
        case RECOMMEND_FAIL:
          state.singleRecommend.loading = false;
          state.singleRecommend.error = payload;
          break;
      }
    },
    editRecommendController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case RECOMMEND_REQUEST:
          state.singleRecommend.loading = true;
          state.singleRecommend.error.message = '';
          break;
        case RECOMMEND_SUCCESS:
          state.singleRecommend.loading = false;
          state.singleRecommend.data = payload;
          state.singleRecommend.error.message = '';
          break;
        case RECOMMEND_FAIL:
          state.singleRecommend.loading = false;
          state.singleRecommend.error = payload;
          break;
      }
    },
    deleteRecommendController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case RECOMMEND_REQUEST:
          state.singleRecommend.loading = true;
          state.singleRecommend.error.message = '';
          break;
        case RECOMMEND_SUCCESS:
          state.singleRecommend.loading = false;
          state.singleRecommend.error.message = '';
          break;
        case RECOMMEND_FAIL:
          state.singleRecommend.loading = false;
          state.singleRecommend.error = payload;
          break;
      }
    },
    addRecommendController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case RECOMMEND_REQUEST:
          state.singleRecommend.loading = true;
          state.singleRecommend.error.message = '';
          break;
        case RECOMMEND_SUCCESS:
          state.singleRecommend.loading = false;
          state.singleRecommend.error.message = '';
          break;
        case RECOMMEND_FAIL:
          state.singleRecommend.loading = false;
          state.singleRecommend.error = payload;
          break;
      }
    }
    
  }
});

export const recommendActions = recommendSlice.actions;
export default recommendSlice;