import classes from './AdminDogGalleryPicker.module.scss';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { galleriesFetch } from '../../../../store/galleries/galleries-actions';
import { RootState } from '../../../../store';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import ErrorBox from '../../../ErrorBox/ErrorBox';
import AdminGalleryPickerGalleryContainer from '../AdminGalleryPickerGalleryContainer/AdminGalleryPickerGalleryContainer';
import Backdrop from '../../../Backdrop/Backdrop';

interface IProps {
  dogName: string;
  onClick: (galleryId: string) => any;
  closeModal: () => any;
}

const AdminDogGalleryPicker = ({ dogName, onClick, closeModal }: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(galleriesFetch());
  }, [dispatch]);

  const galleryies = useSelector((state: RootState) => state.galleries.galleries);
  const { loading, error, data } = galleryies;

  const renderGalleries = data.map((gallery) => (
    <AdminGalleryPickerGalleryContainer
      key={gallery._id}
      _id={gallery._id}
      name={gallery.name}
      images={gallery.images}
      onClick={onClick.bind(this, gallery._id)}
    />
  ));
  
  const isGalleryCanRender = !loading;

  return ReactDOM.createPortal(
    <>
      <Backdrop onClick={closeModal} />
      <div className={classes.modal}>
        <div className={classes['text-container']}>
          <h1>Przypisanie galerii</h1>
          <p>(Kliknij galerię, którą chcesz przypisac do "{dogName}")</p>
        </div>

        { loading && !error.message &&
          <div className={classes['status-container']}>
            <LoadingSpinner />
          </div>
        }
        { error.message &&
            <div className={classes['status-container']}>
              <ErrorBox errorMessage={error.message} />
            </div>
        }
        {isGalleryCanRender && renderGalleries}

      </div>
    </>,
    document.getElementById('gallery-picker')!
  )
};

export default AdminDogGalleryPicker;
