import React from 'react';

interface IProps {
  isRequired: boolean;
}

const FileInput = React.forwardRef<HTMLInputElement, IProps>(({ isRequired }, ref) => {
  return (
    <>
      {
        isRequired
        ? <input ref={ref} type="file" required accept="image/png, image/jpeg, image/jpg" />
        : <input ref={ref} type="file" accept="image/png, image/jpeg, image/jpg" />
      }
    </>
  );
});

export default FileInput;
