import classes from './AdminContainer.module.scss';
import LogoutButton from '../../../Buttons/LogoutButton/LogoutButtn';
import LinkButton from '../../../Buttons/LinkButton/LinkButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';
import { getAdmin, logoutAdmin } from '../../../../store/admin/admin-actions';
import Cookies from 'js-cookie';
import { ADMIN_TOKEN_COOKIE } from '../../../../constants/admin';
import { adminActions } from '../../../../store/admin/admin-slice';

const AdminContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const adminData = useSelector((state: RootState) => state.admin.adminData);
  const {token, admin } = adminData
  const { _id, email } = admin
  
  useEffect(() => {
    if (!token) {
      navigate('/admin/login')
    } else {
      if (!_id || !email) {
        dispatch(getAdmin(Cookies.get(ADMIN_TOKEN_COOKIE)!));
      }
    }

    
  }, [navigate, token, dispatch, _id, email])

  const logoutHandler = () => {
    dispatch(logoutAdmin(Cookies.get(ADMIN_TOKEN_COOKIE)!));
    dispatch(adminActions.clearError());
  }

  const returnToMainPageHandler = () => {
    dispatch(adminActions.clearError());
  }

  const linkButtonToUrl = location.pathname === '/admin' ? `/admin/edit-admin/${_id}` : '/admin';
  const libkButtonText = location.pathname === '/admin' ? `Edytuj Admina` : 'Panel Admina';

  return (
    <div className={classes.container}>
      <div className={classes['admin-container']}>
        <div className={classes['title-icon']} />
        <span>{email}</span>
        <LogoutButton title='Wyloguj się' fontSize={1.15} onClick={logoutHandler} />
      </div>
      <LinkButton to={linkButtonToUrl} onClick={returnToMainPageHandler} title={libkButtonText} fontSize={1.25} />
      
    </div>
  );
};

export default AdminContainer;
