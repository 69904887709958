import axios from "axios";
import { BACKEND_URL } from "../../config/backendData";
import { GALLERIES_FAIL, GALLERIES_REQUEST, GALLERIES_SUCCESS } from "../../constants/galleries";
import { galleriesActions } from "./galleries-slice";

//GET All Galleries
export const galleriesFetch = () => async (dispatch: any) => {
  dispatch(galleriesActions.galleriesFetchController({
    type: GALLERIES_REQUEST
  }));

  const getGalleries = async () => {
    const data = await axios.get(`${BACKEND_URL}/gallery`);
    return data;
  }

  try {
    const { data } = await getGalleries();
    
    dispatch(galleriesActions.galleriesFetchController({
      type: GALLERIES_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(galleriesActions.galleriesFetchController({
      type: GALLERIES_FAIL,
      payload: {message: 'Pobieranie galerii nie powiodło się.'}
    }))
  }
}

//ADD gallery
export const addNewGallery = (name: string, images: FileList, token: string) => async (dispatch: any) => {
  dispatch(galleriesActions.addGalleryController({
    type: GALLERIES_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const formData = new FormData();
  for (let i = 0; i < images.length; i++) {
    formData.append('images', images[i], images[i].name);
  }
  formData.append('name', name);

  const addGalleryImage = async () => {
    const data = await axios.post(`${BACKEND_URL}/gallery`, formData, config);
    return data;
  }

  try {
    await addGalleryImage();
    
    dispatch(galleriesActions.addGalleryController({
      type: GALLERIES_SUCCESS
    }))
    dispatch(galleriesFetch());
  } catch (error) {
    dispatch(galleriesActions.addGalleryController({
      type: GALLERIES_FAIL,
      payload: {message: 'Dodanie galerii nie powiodło się.'}
    }))
  }
}