import classes from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.logo} />
      <div className={classes.authors}>
        <a rel="noreferrer" target='_blank' href='https://www.instagram.com/szopenpogo/'>Created by SzopenScript</a>
        <a rel="noreferrer" target='_blank' href='https://www.instagram.com/equiwings_/'>Designed by EquiWings</a>
      </div>
    </footer>
  );
};

export default Footer;
