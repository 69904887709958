import { createSlice } from "@reduxjs/toolkit";
import { CONTACT_FAIL, CONTACT_REQUEST, CONTACT_SUCCESS } from "../../constants/contact";

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    contactData: {
      loading: false,
      error: {
        message: ''
      },
      contact: {
        fullName: '',
        city: '',
        phoneNumber: '',
        email: '',
        facebook: '',
        instagram: ''
      }
    }
  },
  reducers: {
    contactFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case CONTACT_REQUEST:
          state.contactData.loading = true;
          state.contactData.error.message = '';
          break;
        case CONTACT_SUCCESS:
          state.contactData.loading = false;
          state.contactData.contact = payload;
          state.contactData.error.message = '';
          break;
        case CONTACT_FAIL:
          state.contactData.loading = false;
          state.contactData.error = payload;
          break;
      }
    },
    contactEditController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case CONTACT_REQUEST:
          state.contactData.loading = true;
          state.contactData.error.message = '';
          break;
        case CONTACT_SUCCESS:
          state.contactData.loading = false;
          state.contactData.contact = payload;
          state.contactData.error.message = '';
          break;
        case CONTACT_FAIL:
          state.contactData.loading = false;
          state.contactData.error = payload;
          break;
      }
    }
  }
});

export const contactActions = contactSlice.actions;
export default contactSlice;