import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setActualDevice } from '../../../store/window/userWindow-actions';
import AdminContainer from '../../Containers/admin/AdminContainer/AdminContainer';
import CookiesInfo from '../../CookiesInfo/CookiesInfo';
import ErrorBox from '../../ErrorBox/ErrorBox';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Footer from '../../Ui/Footer/Footer';
import Header from '../../Ui/Header/Header';
import classes from './AdminLayout.module.scss';

interface IProps {
  children: any;
  loading: boolean;
  error: {
    message: string
  };
}

const AdminLayout = ({children, loading, error}: IProps) => {
  const dispatch = useDispatch();

  const [isCookiesInfo, setIsCookiesInfo] = useState<boolean>(Cookies.get('cookies') === 'true' ? false : true);

  const setDisplayContent = () => {
    dispatch(setActualDevice(window.innerWidth));
  }
  window.addEventListener('resize', setDisplayContent);
  setDisplayContent();

  const closeCookiesWindowHandler = () => {
    Cookies.set('cookies', 'true');
    setIsCookiesInfo(Cookies.get('cookies') === 'true' ? false : true);
  }

  document.title = 'Child of Love - ADMIN'

  const isChildrenCanRender = !loading && !error.message;
  return (
    <>
      {isCookiesInfo && <CookiesInfo onClick={closeCookiesWindowHandler} />} 
      <Header />
      <AdminContainer />
      <main className={classes['admin-container']}>
        { loading && !error.message &&
          <div className={classes['status-container']}>
            <LoadingSpinner />
          </div>
        }
        { error.message &&
          <div className={classes['status-container']}>
            <ErrorBox errorMessage={error.message} />
          </div>
        }
        { isChildrenCanRender &&
          <>
            {children}
          </>
        }
        
      </main>
      <Footer />
    </>
  );
};

export default AdminLayout;
