import React, { useState } from 'react';
import classes from './TextAreaInput.module.scss';

interface IProps {
  value: string;
}

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, IProps>(({ value }, ref) => {
  const [inputValue, setInputValue] = useState(value);
  
  const updateInputValue = (event: any) => {
    setInputValue(event.target.value);
  }

  return (
    <>
      <textarea
        ref={ref}
        value={inputValue}
        onChange={updateInputValue}
        className={classes['textarea-input']}
      />
    </>
  );
});

export default TextAreaInput;
