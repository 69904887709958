import classes from './SubmitButton.module.scss';

interface IProps {
  title: string;
  fontSize: number
}

const SubmitButton = ({title, fontSize}: IProps) => {
  return (
    <button
      type='submit'
      className={classes['submit-button']}
      style={{fontSize: `${fontSize}rem`}}
    >
      {title}
    </button>
  );
};

export default SubmitButton;
