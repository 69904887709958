import axios from "axios";
import { BACKEND_URL, CONTACT_ID } from "../../config/backendData";
import { CONTACT_FAIL, CONTACT_REQUEST, CONTACT_SUCCESS } from "../../constants/contact";
import { contactActions } from "./contact-slice";

//GET contact
export const contactFetch = () => async (dispatch: any) => {
  dispatch(contactActions.contactFetchController({
    type: CONTACT_REQUEST
  }));

  const getContact = async () => {
    const data = await axios.get(`${BACKEND_URL}/contact/${CONTACT_ID}`);
    return data;
  }

  try {
    const { data } = await getContact();
    
    dispatch(contactActions.contactFetchController({
      type: CONTACT_SUCCESS,
      payload: data
    }));
  } catch (error) {
    dispatch(contactActions.contactFetchController({
      type: CONTACT_FAIL,
      payload: {message: 'Pobieranie galerii nie powiodło się.'}
    }))
  }
}

//EDIT contact
export const editContact = (
  fullName: string,
  city: string,
  phoneNumber: number,
  email: string,
  facebook: string,
  instagram: string,
  token: string
) => async (dispatch: any) => {

  dispatch(contactActions.contactEditController({
    type: CONTACT_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const editedContact = {
    fullName,
    city,
    phoneNumber,
    email,
    facebook,
    instagram
  }

  const editContact = async () => {
    const data = await axios.patch(`${BACKEND_URL}/contact/${CONTACT_ID}`, editedContact, config);
    return data;
  }

  try {
    const { data } = await editContact();
    
    dispatch(contactActions.contactEditController({
      type: CONTACT_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(contactActions.contactEditController({
      type: CONTACT_FAIL,
      payload: {message: 'Edycja kontaktu nie powiodło się.'}
    }))
  }
}