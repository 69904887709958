import Cookies from 'js-cookie';
import React, { SyntheticEvent, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ADMIN_TOKEN_COOKIE } from '../../../../constants/admin';
import { addNewGallery } from '../../../../store/galleries/galleries-actions';
import SubmitButton from '../../../Buttons/SubmitButton/SubmitButton';
import MainForm from '../../../Forms/MainForm/MainForm';
import TextInput from '../../../Inputs/TextInput/TextInput';
import classes from './AdminAddGallery.module.scss';

const AdminAddGallery = () => {
  const dispatch = useDispatch();

  const nameInputRef = useRef<HTMLInputElement>(null);
  const filesInputRef = useRef<HTMLInputElement>(null);

  const submitAddGallery = (event: SyntheticEvent) => {
    event.preventDefault();

    const nameValue = nameInputRef.current!.value;
    const filesData = filesInputRef.current!.files;

    dispatch(addNewGallery(nameValue, filesData!, Cookies.get(ADMIN_TOKEN_COOKIE)!));
    window.scrollTo({ top: document.body.clientHeight, behavior: 'smooth' })
  }

  return (
    <div className={classes['form-container']}>
      <MainForm onSubmit={submitAddGallery}>

        <label>Nazwa:</label>
        <TextInput ref={nameInputRef} isValid={true} value={''} />
        <label>Dodaj zdjęcia</label>
        <input ref={filesInputRef} type="file" multiple required accept="image/png, image/jpeg, image/jpg" />
        
        <SubmitButton title="DODAJ" fontSize={1.5} />
      </MainForm>
    </div>
  );
};

export default AdminAddGallery;
