import classes from './MainContainer.module.scss';

interface IProps {
  children: any;
}

const MainContainer = ({children}: IProps) => {
  return (
    <div className={classes.container}>
      {children}
    </div>
  );
};

export default MainContainer;
