
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminAddRecommendation from "../../../components/Containers/admin/AdminAddRecommendation/AdminAddRecommendation";
import AdminRecommend from "../../../components/Containers/admin/AdminRecommend/AdminRecommend";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { RootState } from "../../../store";
import { recommendsFetch } from "../../../store/recommend/recommend-actions";
import classes from './AdminRecommendPage.module.scss';

const AdminRecommendPage = () => {
  const dispatch = useDispatch();

  const recommendations = useSelector((state: RootState) => state.recommend.recommend);
  const { loading, error, data } = recommendations;
  
  useEffect(() => {
    dispatch(recommendsFetch());
    window.scrollTo({ top: 250, behavior: 'smooth' });
  }, [dispatch]);

  const renderAdminRecomendations = data.map(recommend => (
    <AdminRecommend
      key={recommend._id}
      _id={recommend._id}
      name={recommend.name}
      imageUrl={recommend.imageUrl}
      recommendUrl={recommend.recommendUrl}
    />
  ));

  return (
    <AdminLayout loading={loading} error={error} >
      <section className={classes.wrapper}>
        <div className={classes.container}>
          <h1>Dodaj nowe polecane:</h1>
          <AdminAddRecommendation />
        </div>

        <div className={classes.container}>
          <h1>Edytuj istniejące polecamy:</h1>
          <p>Kliknij w wybrany obrazek aby przejść do panelu edycji</p>
          <div className={classes['recommend-container']}>
            {renderAdminRecomendations}
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default AdminRecommendPage;
