
import Cookies from "js-cookie";
import { SyntheticEvent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import BackButton from "../../../components/Buttons/BackButton/BackButton";
import SubmitButton from "../../../components/Buttons/SubmitButton/SubmitButton";
import MainForm from "../../../components/Forms/MainForm/MainForm";
import GalleryImage from "../../../components/GalleryImage/GalleryImage";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { ADMIN_TOKEN_COOKIE } from "../../../constants/admin";
import { RootState } from "../../../store";
import { addImagesToGallery, deleteGallery, deleteImageGallery, editGallery, galleryFetch } from "../../../store/gallery/gallery-actions";
import classes from './AdminGalleryEditPage.module.scss';

const AdminGalleryEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const nameInputRef = useRef<HTMLInputElement>(null);
  const filesInputRef = useRef<HTMLInputElement>(null);
  
  const gallery = useSelector((state: RootState) => state.gallery.gallery);
  const { loading, error, data } = gallery;

  useEffect(() => {
    dispatch(galleryFetch(id!));
    window.scrollTo({ top: 400, behavior: 'smooth' });
  }, [dispatch, id]);

  const submitEditDataHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const nameValue = nameInputRef.current!.value;
    
    dispatch(editGallery(data._id, nameValue, Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  
  const submitDeleteGalleryHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    dispatch(deleteGallery(data._id, Cookies.get(ADMIN_TOKEN_COOKIE)!));
    navigate(-1);
  }

  const submitImagesHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const filesData = filesInputRef.current!.files;
    dispatch(addImagesToGallery(data._id, filesData!, Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  const sumbitDeleteImageHandler = (image: string) => {
    dispatch(deleteImageGallery(data._id, image, Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  const renderImages = data.images.map((image) => (
    <GalleryImage
      key={image}
      image={image}
      onClick={sumbitDeleteImageHandler.bind(this, image)}
    >
      <div className={classes['delete-photo-image']} />
    </GalleryImage>
  ))

  return (
    <AdminLayout loading={loading} error={error} >
      <div className={classes.container}>
        <h1>Edytuj "{ data.name }"</h1>
        <BackButton title="Wróć" fontSize={1.35} />

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitEditDataHandler}>
            <h3>Edytuj nazwę:</h3>

            <label>Nazwa:</label>
            <TextInput ref={nameInputRef} isValid={true} value={data.name} />

            <SubmitButton title="ZAPISZ" fontSize={1.5} />
          </MainForm>
        </div>

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitImagesHandler}>
            <h3>Dodaj zdjęcia:</h3>
            <div className={classes['file-picker-container']}>
              <input ref={filesInputRef} type="file" multiple required accept="image/png, image/jpeg, image/jpg" />
            </div>
            <SubmitButton title="ZAPISZ" fontSize={1.5} />
          </MainForm>
        </div>

        <div className={classes['delete-photo-container']}>
          <h3>Usuń zdjęcie:</h3>
          <p>Kliknięcie na zdjęcie spowoduje<br />jego trwałe usunięcie</p>
          <div className={classes['images-wrapper']}>
            {renderImages}
          </div>
        </div>
        

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitDeleteGalleryHandler}>
            <h3>Usuń galerię</h3>
            <label>Kliknięcie przycisku spowoduje<br />trwałe usunięcie galerii</label>
            <SubmitButton title="USUŃ" fontSize={1.5} />
          </MainForm>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminGalleryEditPage;
