import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'js-cookie'
import { ADMIN_FAIL, ADMIN_REQUEST, ADMIN_SUCCESS, ADMIN_TOKEN_COOKIE } from "../../constants/admin";

const tokenCookies = Cookies.get(ADMIN_TOKEN_COOKIE) && Cookies.get(ADMIN_TOKEN_COOKIE) !== 'undefined'
  ? Cookies.get(ADMIN_TOKEN_COOKIE)
  : '';
const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    adminData: {
      loading: false,
      error: {
        message: ''
      },
      token: tokenCookies,
      admin: {
        _id: '',
        email: ''
      }
    }
  },
  reducers: {
    clearError(state) {
      state.adminData.error.message = '';
    },
    loginAdmin(state, action) {
      const { type, token, payload } = action.payload;

      switch (type) {
        case ADMIN_REQUEST:
          state.adminData.loading = true;
          state.adminData.token = '';
          break;
        case ADMIN_SUCCESS:
          state.adminData.loading = false;
          state.adminData.token = token;
          state.adminData.admin = payload;
          state.adminData.error.message = '';
          
          Cookies.set(ADMIN_TOKEN_COOKIE, token, { expires: 7 });
          break;
        case ADMIN_FAIL:
          state.adminData.loading = false;
          state.adminData.error = payload;
          state.adminData.token = '';
          break;
      }
    },
    getAdmin(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case ADMIN_REQUEST:
          state.adminData.loading = true;
          break;
        case ADMIN_SUCCESS:
          state.adminData.admin = payload;
          state.adminData.loading = false;
          state.adminData.error.message = '';
          break;
        case ADMIN_FAIL:
          state.adminData.token = '';
          state.adminData.loading = false;
          Cookies.remove(ADMIN_TOKEN_COOKIE);
          break;
      }
    },
    logoutAdmin(state, action) {
      const { type } = action.payload;
      
      switch (type) {
        case ADMIN_REQUEST:
          state.adminData.loading = true;
          break;
        case ADMIN_SUCCESS:
          state.adminData.admin._id = '';
          state.adminData.admin.email = '';
          state.adminData.token = '';
          state.adminData.error.message = '';
          Cookies.remove(ADMIN_TOKEN_COOKIE);
          state.adminData.loading = false;
          break;
        case ADMIN_FAIL:
          state.adminData.loading = false;
          break;
      }
    },
    changePassword(state, action) {
      const { type, payload } = action.payload;

      switch (type) {
        case ADMIN_REQUEST:
          state.adminData.loading = true;
          state.adminData.error.message = '';
          break;
        case ADMIN_SUCCESS:
          state.adminData.loading = false;
          state.adminData.error.message = '';
          break;
        case ADMIN_FAIL:
          state.adminData.loading = false;
          state.adminData.error = payload;
          break;
      }
    }
  }
});

export const adminActions = adminSlice.actions;
export default adminSlice;