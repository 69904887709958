
import Cookies from "js-cookie";
import { SyntheticEvent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../../../components/Buttons/BackButton/BackButton";
import SubmitButton from "../../../components/Buttons/SubmitButton/SubmitButton";
import ChangePhotoContainer from "../../../components/Containers/ChangePhotoContainer/ChangePhotoContainer";
import MainForm from "../../../components/Forms/MainForm/MainForm";
import FileInput from "../../../components/Inputs/FileInput/FileInput";
import TextAreaInput from "../../../components/Inputs/TextAreaInput/TextAreaInput";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { ADMIN_TOKEN_COOKIE } from "../../../constants/admin";
import { RootState } from "../../../store";
import { aboutFetch, deleteImageAbout, editAbout, editImageAbout } from "../../../store/about/about-actions";
import classes from './AdminAbout.module.scss';

const AdminAbout = () => {
  const dispatch = useDispatch();

  const titleInputRef = useRef<HTMLInputElement>(null);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  
  useEffect(() => {
    dispatch(aboutFetch());
    window.scrollTo({ top: 400, behavior: 'smooth' });
  }, [dispatch]);


  const about = useSelector((state: RootState) => state.about.about);
  const { loading, error, data } = about;
  

  const submitEditDataHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const titleValue = titleInputRef.current!.value;
    const textValue = textInputRef.current!.value;
    
    dispatch(editAbout(titleValue, textValue, Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  const submitEditPhotoHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const fileData = fileInputRef.current!.files![0];
    dispatch(editImageAbout(fileData, Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  const submitDeletePhotoHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    dispatch(deleteImageAbout(Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  return (
    <AdminLayout loading={loading} error={error} >
      <div className={classes.container}>
        <h1>Edytuj "O Nas"</h1>
        <BackButton title="Wróć" fontSize={1.35} />

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitEditDataHandler}>
            <h3>Edytuj dane:</h3>
            <label>Tytuł:</label>
            <TextInput ref={titleInputRef} isValid={true} value={data.title} />
            <label>Tekst:</label>
            <TextAreaInput ref={textInputRef} value={data.text} />
            <SubmitButton title="ZAPISZ" fontSize={1.5} />
          </MainForm>
        </div>

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitEditPhotoHandler}>
            <h3>Edytuj zdjęcie:</h3>
            {data.imageUrl && <div className={classes['form-image']}>
              <ChangePhotoContainer imageUrl={data.imageUrl} />
            </div>}
            <div className={classes['file-picker-container']}>
              <FileInput ref={fileInputRef} isRequired={true} />
            </div>
            <SubmitButton title="ZAPISZ" fontSize={1.5} />
          </MainForm>
        </div>

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitDeletePhotoHandler}>
            <h3>Usuń zdjęcie</h3>
            <label>Kliknięcie przycisku spowoduje<br />trwałe usunięcie zdjęcia pod tekstem</label>
            <SubmitButton title="USUŃ" fontSize={1.5} />
          </MainForm>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminAbout;
