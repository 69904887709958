import classes from './LandingPage.module.scss';


import fciImage from '../../images/mainPage/fciLogo.png';
import zkwpImage from '../../images/mainPage/zkwpLogo.png';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEffect } from 'react';
import { aboutFetch } from '../../store/about/about-actions';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import { BACKEND_URL } from '../../config/backendData';

const LandingPage = () => {
  const fciUrl = 'http://www.fci.be/en/';
  const zkwpUrl = 'https://www.zkwp.pl/';

  const dispatch = useDispatch();

  const about = useSelector((state: RootState) => state.about.about);
  const { loading, error, data } = about;
  const { title, text, imageUrl } = data;
  

  useEffect(() => {
    dispatch(aboutFetch());
    
  }, [dispatch]);

  return (
    <MainLayout>
      <section className={classes.container}>

        <div className={classes['quote-container']}>
          <h1>„Prawdziwy przyjaciel pozostawi <br /> ślady łap na Twoim sercu”</h1>
          <div className={classes['quote-image']} />
        </div>

        <div className={classes['about-container']}>
          { loading && 
          <div className={classes['status-container']}>
            <LoadingSpinner />
          </div>
          }

          { error.message &&
          <div className={classes['status-container']}>
            <ErrorBox errorMessage={error.message} />
          </div>
          }

          {title && <h1>{title}</h1>}
          {text && <p>{text}</p>}
          {imageUrl && <img src={`${BACKEND_URL}/${imageUrl}`} alt={imageUrl} />}
        </div>

        <div className={classes['images-container']}>
          <div className={classes['dog-logo']} />
          <div className={classes['url-container']}>
            <a rel="noreferrer" target='_blank' href={fciUrl}>
              <img src={fciImage} alt={fciImage} className={classes.image} />
            </a>
            <a rel="noreferrer" target='_blank' href={zkwpUrl}>
              <img src={zkwpImage} alt={zkwpImage} className={classes.image} />
            </a>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default LandingPage;
