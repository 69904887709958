
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminAddDog from "../../../components/Containers/admin/AdminAddDog/AdminAddDog";
import AdminDogContainer from "../../../components/Containers/admin/AdminDogContainer/AdminDogContainer";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { RootState } from "../../../store";
import { dogsFetch } from "../../../store/dogs/dogs-actions";
import classes from './AdminOurDogsPage.module.scss';

const AdminOurDogsPage = () => {
  const dispatch = useDispatch();

  
  useEffect(() => {
    dispatch(dogsFetch());
    window.scrollTo({ top: 250, behavior: 'smooth' });
  }, [dispatch]);

  const ourDogs = useSelector((state: RootState) => state.dogs.dogs);
  const { loading, error, data } = ourDogs;
  
  const renderDogs = data.map((dog) => (
    <AdminDogContainer
      key={dog._id}
      _id={dog._id}
      name={dog.name}
      description={dog.description}
      imageUrl={dog.imageUrl}
    />
  ))
  

  return (
    <AdminLayout loading={loading} error={error} >
      <section className={classes.wrapper}>
        <div className={classes.container}>
          <h1>Dodaj nową sukę:</h1>
          <div className={classes['dog-container']}>
            <AdminAddDog />
          </div>
        </div>

        <div className={classes.container}>
          <h1>Edytuj istniejące suki:</h1>
          <div className={classes['dog-container']}>
            {renderDogs}
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default AdminOurDogsPage;
