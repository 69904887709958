import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Recommend from '../../components/Recommend/Recommend';
import { RootState } from '../../store';
import { recommendsFetch } from '../../store/recommend/recommend-actions';
import classes from './Recommend.module.scss';

const RecommendPage = () => {
  const dispatch = useDispatch();
  
  const isMobile = useSelector((state: RootState) => state.userWindow.isMobile);
  const recommendations = useSelector((state: RootState) => state.recommend.recommend);
  const { loading, error, data } = recommendations;
  
  useEffect(() => {
    dispatch(recommendsFetch());
    
  }, [dispatch]);

  const renderRecomendations = data.map(recommend => (
    <Recommend
      key={recommend._id}
      _id={recommend._id}
      name={recommend.name}
      imageUrl={recommend.imageUrl}
      recommendUrl={recommend.recommendUrl}
    />
  ));

  return (
    <MainLayout>
      <section className={classes['recommend-container']}>
        {isMobile && <h1 className={classes['recommend-title']}>Polecamy</h1>}
        { loading && 
        <div className={classes['status-container']}>
          <LoadingSpinner />
        </div>
        }

        { error.message &&
        <div className={classes['status-container']}>
          <ErrorBox errorMessage={error.message} />
        </div>
        }

        {data[0] && renderRecomendations}
      </section>
    </MainLayout>
  );
};

export default RecommendPage;
