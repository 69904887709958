
import Cookies from "js-cookie";
import { SyntheticEvent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import BackButton from "../../../components/Buttons/BackButton/BackButton";
import SubmitButton from "../../../components/Buttons/SubmitButton/SubmitButton";
import ChangePhotoContainer from "../../../components/Containers/ChangePhotoContainer/ChangePhotoContainer";
import MainForm from "../../../components/Forms/MainForm/MainForm";
import FileInput from "../../../components/Inputs/FileInput/FileInput";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { ADMIN_TOKEN_COOKIE } from "../../../constants/admin";
import { RootState } from "../../../store";
import { deleteRecommend, editImageRecommend, editRecommend, singleRecommendFetch } from "../../../store/recommend/recommend-actions";
import classes from './AdminEditRecommendPage.module.scss';

const AdminEditRecommendPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const nameInputRef = useRef<HTMLInputElement>(null);
  const linkInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  
  useEffect(() => {
    dispatch(singleRecommendFetch(id!));
    window.scrollTo({ top: 400, behavior: 'smooth' });
  }, [dispatch, id]);


  const recommendations = useSelector((state: RootState) => state.recommend.singleRecommend);
  const { loading, error, data } = recommendations;

  const submitEditDataHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const nameValue = nameInputRef.current!.value;
    const linkValue = linkInputRef.current!.value;
    
    dispatch(editRecommend(data._id, nameValue, linkValue, Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  const submitEditPhotoHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const fileData = fileInputRef.current!.files![0];
    dispatch(editImageRecommend(data._id, fileData, Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  const submitDeletePhotoHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    dispatch(deleteRecommend(data._id, Cookies.get(ADMIN_TOKEN_COOKIE)!));
    navigate(-1);
  }

  return (
    <AdminLayout loading={loading} error={error} >
      <div className={classes.container}>
        <h1>Edytuj "{ data.name }"</h1>
        <BackButton title="Wróć" fontSize={1.35} />

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitEditDataHandler}>
            <h3>Edytuj dane:</h3>
            <label>Nazwa:</label>
            <TextInput ref={nameInputRef} isValid={true} value={data.name} />
            <label>Link:</label>
            <TextInput ref={linkInputRef} isValid={true} value={data.recommendUrl} />
            <SubmitButton title="ZAPISZ" fontSize={1.5} />
          </MainForm>
        </div>

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitEditPhotoHandler}>
            <h3>Edytuj zdjęcie:</h3>
            <div
              className={classes['form-image']}
            >
              <ChangePhotoContainer imageUrl={data.imageUrl} />
            </div>
            <div className={classes['file-picker-container']}>
              <FileInput ref={fileInputRef} isRequired={true} />
            </div>
            <SubmitButton title="ZAPISZ" fontSize={1.5} />
          </MainForm>
        </div>

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitDeletePhotoHandler}>
            <h3>Usuń polecane</h3>
            <label>Kliknięcie przycisku spowoduje<br />trwałe usunięcie "polecanego"</label>
            <SubmitButton title="USUŃ" fontSize={1.5} />
          </MainForm>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminEditRecommendPage;
