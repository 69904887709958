import React from 'react';
import classes from './EmailInput.module.scss';

interface IProps {
  title: string;
  isRequired: boolean;
}

const EmailInput = React.forwardRef<HTMLInputElement, IProps>(({title, isRequired}, ref) => {
  return (
    <>
      {
        isRequired
        ? <input ref={ref} type='email' placeholder={title} required className={classes['email-input']} />
        : <input ref={ref} type='email' placeholder={title} className={classes['email-input']} />
      }
    </>
  );
});

export default EmailInput;
