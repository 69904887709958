import axios from "axios";
import { BACKEND_URL } from "../../config/backendData";
import { RECOMMEND_FAIL, RECOMMEND_REQUEST, RECOMMEND_SUCCESS } from "../../constants/recommend";
import { recommendActions } from "./recommend-slice"; 

//Get All Recomendations
export const recommendsFetch = () => async (dispatch: any) => {
  dispatch(recommendActions.recommendFetchController({
    type: RECOMMEND_REQUEST
  }));

  const getRecommend = async () => {
    const data = await axios.get(`${BACKEND_URL}/recommend`);
    return data;
  }

  try {
    const { data } = await getRecommend();
    
    dispatch(recommendActions.recommendFetchController({
      type: RECOMMEND_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(recommendActions.recommendFetchController({
      type: RECOMMEND_FAIL,
      payload: {message: 'Pobieranie rekomendacji nie powiodło się.'}
    }))
  }
}

//Get single recomendation
export const singleRecommendFetch = (_id: string) => async (dispatch: any) => {
  dispatch(recommendActions.singleRecommendFetchController({
    type: RECOMMEND_REQUEST
  }));

  const getSingleRecommend = async () => {
    const data = await axios.get(`${BACKEND_URL}/recommend/${_id}`);
    return data;
  }

  try {
    const { data } = await getSingleRecommend();
    
    dispatch(recommendActions.singleRecommendFetchController({
      type: RECOMMEND_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(recommendActions.singleRecommendFetchController({
      type: RECOMMEND_FAIL,
      payload: {message: 'Pobieranie rekomendacji nie powiodło się.'}
    }))
  }
}

//Edit recomendation
export const editRecommend = (_id: string, name: string, recommendUrl: string, token: string) => async (dispatch: any) => {
  dispatch(recommendActions.editRecommendController({
    type: RECOMMEND_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const editedRecommendation = {
    name,
    recommendUrl
  }

  const editRecommend = async () => {
    const data = await axios.patch(`${BACKEND_URL}/recommend/data/${_id}`, editedRecommendation, config);
    return data;
  }

  try {
    const { data } = await editRecommend();
    
    dispatch(recommendActions.editRecommendController({
      type: RECOMMEND_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(recommendActions.editRecommendController({
      type: RECOMMEND_FAIL,
      payload: {message: 'Edycja rekomendacji nie powiodło się.'}
    }))
  }
}

//Edit Image recomendation
export const editImageRecommend = (_id: string, image: File, token: string) => async (dispatch: any) => {
  dispatch(recommendActions.editRecommendController({
    type: RECOMMEND_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const formData = new FormData();
  formData.append('image', image, image.name);

  const editRecommend = async () => {
    const data = await axios.patch(`${BACKEND_URL}/recommend/image/${_id}`, formData, config);
    return data;
  }

  try {
    const { data } = await editRecommend();
    
    dispatch(recommendActions.editRecommendController({
      type: RECOMMEND_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(recommendActions.editRecommendController({
      type: RECOMMEND_FAIL,
      payload: {message: 'Edycja rekomendacji nie powiodło się.'}
    }))
  }
}

//Delete single recomendation
export const deleteRecommend = (_id: string, token: string) => async (dispatch: any) => {
  dispatch(recommendActions.deleteRecommendController({
    type: RECOMMEND_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const deleteSingleRecommend = async () => {
    const data = await axios.delete(`${BACKEND_URL}/recommend/${_id}`, config);
    return data;
  }

  try {
    await deleteSingleRecommend();
    
    dispatch(recommendActions.deleteRecommendController({
      type: RECOMMEND_SUCCESS
    }));
    
  } catch (error) {
    dispatch(recommendActions.deleteRecommendController({
      type: RECOMMEND_FAIL,
      payload: {message: 'Usunięcie rekomendacji nie powiodło się.'}
    }))
  }
}

//Add recomendation
export const addRecommend = (name: string, recommendUrl: string, image: File, token: string) => async (dispatch: any) => {
  dispatch(recommendActions.addRecommendController({
    type: RECOMMEND_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const formData = new FormData();
  formData.append('image', image, image.name);
  formData.append('name', name);
  formData.append('recommendUrl', recommendUrl);

  const addRecommend = async () => {
    const data = await axios.post(`${BACKEND_URL}/recommend`, formData, config);
    return data;
  }

  try {
    await addRecommend();
    
    dispatch(recommendActions.addRecommendController({
      type: RECOMMEND_SUCCESS
    }))
    dispatch(recommendsFetch());
  } catch (error) {
    dispatch(recommendActions.addRecommendController({
      type: RECOMMEND_FAIL,
      payload: {message: 'Dodanie rekomendacji nie powiodło się.'}
    }))
  }
}