import classes from './MainButton.module.scss';

interface IProps {
  title: string;
  fontSize: number;
  onClick: () => any;
}

const MainButton = ({title, fontSize, onClick}: IProps) => {
  return (
    <button
      type='button'
      className={classes['main-button']}
      onClick={onClick}
      style={
        {fontSize: `${fontSize}rem`}
      }
    >
      {title}
    </button>
  );
};

export default MainButton;
