import { INavButton } from "../interfaces/INavButton";

export const navBarButtons: Array<INavButton> = [
  {
    title: 'O NAS',
    route: '/'
  }, {
    title: 'NASZE SUKI',
    route: '/nasze-suki'
  }, {
    title: 'SZCZENIĘTA',
    route: '/szczenieta'
  }, {
    title: 'GALERIA',
    route: '/galeria'
  }, {
    title: 'POLECAMY',
    route: '/polecamy'
  }, {
    title: 'KONTAKT',
    route: '/kontakt'
  }
];