import { BACKEND_URL } from '../../../config/backendData';
import classes from './ChangePhotoContainer.module.scss';

interface IProps {
  imageUrl: string
}

const ChangePhotoContainer = ({ imageUrl }: IProps) => {

  const backroundImageUrl = imageUrl ? `${BACKEND_URL}/${imageUrl}` : '';

  return (
    <div
      className={classes['form-image']}
      style={{ backgroundImage: `url('${backroundImageUrl}')` }}
    >
      <p>Akualne zdjęcie</p>
    </div>
  );
};

export default ChangePhotoContainer;
