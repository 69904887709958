import React, { useState } from 'react';
import classes from './NumberInput.module.scss';

interface IProps {
  value: string;
}

const NumberInput = React.forwardRef<HTMLInputElement, IProps>(({ value }, ref) => {
  const [inputValue, setInputValue] = useState(value);

  
  const updateInputValue = (event: any) => {
    setInputValue(event.target.value);
  }

  return (
    <>
      <input ref={ref} type='number' value={inputValue} onChange={updateInputValue} className={classes['number-input']} />
    </>
  );
});

export default NumberInput;
