import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { navBarButtons } from '../../../constants/navBar';
import { RootState } from '../../../store';
import NavButton from '../../Buttons/NavButton/NavButton';
import classes from './NavBar.module.scss';

const NavBar = () => {

  const isWindowScrolled = useSelector((state: RootState) => state.userWindow.isWindowScrolled);

  const renderNavButtons = navBarButtons.map(data => (
    <NavButton key={data.route} title={data.title} route={data.route} />
  ))

  const navClass = isWindowScrolled ? `${classes.navbar} ${classes['navbar--active']}` : classes.navbar;

  return (
    <nav className={navClass}>
      {renderNavButtons}
    </nav>
  );
};

export default NavBar;
