import { createSlice } from "@reduxjs/toolkit";
import { PUPPIES_FAIL, PUPPIES_REQUEST, PUPPIES_SUCCESS } from "../../constants/puppies";

const puppiesSlice = createSlice({
  name: 'puppies',
  initialState: {
    puppies: {
      loading: false,
      error: {
        message: ''
      },
      data: [{
        _id: '',
        name: '',
        description: '',
        imageUrl: '',
        galleryUrl: ''
      }]
    },
    puppy: {
      loading: false,
      error: {
        message: ''
      },
      data: {
        _id: '',
        name: '',
        description: '',
        imageUrl: '',
        galleryUrl: ''
      }
    }
  },
  reducers: {
    puppiesFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PUPPIES_REQUEST:
          state.puppies.loading = true;
          state.puppies.error.message = '';
          break;
        case PUPPIES_SUCCESS:
          state.puppies.loading = false;
          state.puppies.data = payload;
          state.puppies.error.message = '';
          break;
        case PUPPIES_FAIL:
          state.puppies.loading = false;
          state.puppies.error = payload;
          break;
      }
    },
    singlePuppyFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PUPPIES_REQUEST:
          state.puppy.loading = true;
          state.puppy.error.message = '';
          break;
        case PUPPIES_SUCCESS:
          state.puppy.loading = false;
          state.puppy.data = payload;
          state.puppy.error.message = '';
          break;
        case PUPPIES_FAIL:
          state.puppy.loading = false;
          state.puppy.error = payload;
          break;
      }
    },
    editPuppyFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PUPPIES_REQUEST:
          state.puppy.loading = true;
          state.puppy.error.message = '';
          break;
        case PUPPIES_SUCCESS:
          state.puppy.loading = false;
          state.puppy.data = payload;
          state.puppy.error.message = '';
          break;
        case PUPPIES_FAIL:
          state.puppy.loading = false;
          state.puppy.error = payload;
          break;
      }
    },
    deletePuppyController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PUPPIES_REQUEST:
          state.puppy.loading = true;
          state.puppy.error.message = '';
          break;
        case PUPPIES_SUCCESS:
          state.puppy.loading = false;
          state.puppy.error.message = '';
          break;
        case PUPPIES_FAIL:
          state.puppy.loading = false;
          state.puppy.error = payload;
          break;
      }
    },
    addPuppyController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PUPPIES_REQUEST:
          state.puppy.loading = true;
          state.puppy.error.message = '';
          break;
        case PUPPIES_SUCCESS:
          state.puppy.loading = false;
          state.puppy.error.message = '';
          break;
        case PUPPIES_FAIL:
          state.puppy.loading = false;
          state.puppy.error = payload;
          break;
      }
    }
  }
});

export const puppiesActions = puppiesSlice.actions;
export default puppiesSlice;