import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import GalleryContainer from '../../components/GalleryContainer/GalleryContainer';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { RootState } from '../../store';
import { galleriesFetch } from '../../store/galleries/galleries-actions';
import classes from './GalleriesPage.module.scss';

const GalleriesPage = () => {
  const dispatch = useDispatch();
  
  const isMobile = useSelector((state: RootState) => state.userWindow.isMobile);
  const galleries = useSelector((state: RootState) => state.galleries.galleries);
  const { loading, error, data } = galleries;
  
  useEffect(() => {
    dispatch(galleriesFetch());
    
  }, [dispatch]);

  const renderGalleries = data.map(gallery => (
    <GalleryContainer key={gallery._id} _id={gallery._id} name={gallery.name} images={gallery.images} />
  ))

  return (
    <MainLayout>
      <section className={classes['galleries-container']}>
        {isMobile && <h1 className={classes['galleries-title']}>Galeria</h1>}
        { loading && 
        <div className={classes['status-container']}>
          <LoadingSpinner />
        </div>
        }

        { error.message &&
        <div className={classes['status-container']}>
          <ErrorBox errorMessage={error.message} />
        </div>
        }

        {data[0] && renderGalleries}
      </section>
    </MainLayout>
  );
};

export default GalleriesPage;
