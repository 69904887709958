import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { INavButton } from '../../../interfaces/INavButton';
import { uiActions } from '../../../store/ui/ui-slice';
import classes from './NavButtonMobile.module.scss';

const NavButtonMobile = ({ title, route }: INavButton) => {
  const dispatch = useDispatch();

  const classController = (isActive: boolean) => {
    let NavClass = classes['nav-button'];
    const NavActiveClass = `${classes['nav-button--active']} ${classes['nav-button']}`

    return isActive ? NavActiveClass : NavClass;
  }

  const clickHandler = () => {
    dispatch(uiActions.toggleMobileMenu());

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <NavLink
      className={(navData) => classController(navData.isActive)}
      onClick={clickHandler}
      to={route}
    >
      {title}
    </NavLink>
  );
};

export default NavButtonMobile;
