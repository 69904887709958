import classes from './LoadingSpinner.module.scss';

const LoadingSpinner = () => {
  return (
    <div className={classes['loading-spinner__container']}>
      <div className={classes['loading-spinner']} />
      <h1>ładowanie...</h1>
    </div>
  );
};

export default LoadingSpinner;
