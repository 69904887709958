import classes from './GalleryImage.module.scss';
import { IGallery } from '../../interfaces/IGallery';
import { BACKEND_URL } from '../../config/backendData';

const GalleryImage = ({ image, children, onClick }: IGallery) => {
  const imageUrl = `${BACKEND_URL}/${image}`;
  
  return (
    <div
      className={classes['gallery-image']}
      style={
        { backgroundImage: `url('${imageUrl}')` }
      }
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default GalleryImage;
