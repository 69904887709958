import classes from './ContactField.module.scss';

interface IProps {
  data: string;
  title: string;
  imageUrl: string;
}

const ContactField = ({ data, title, imageUrl }: IProps) => {
  return (
    <div className={classes['contact-field-container']}>
      <img src={imageUrl} alt={`child-of-love-${title}`} />
      <span>{data}</span>
    </div>
  );
};
export default ContactField;
