import Cookies from "js-cookie";
import { SyntheticEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainButton from "../../../components/Buttons/MainButton/MainButton";
import SubmitButton from "../../../components/Buttons/SubmitButton/SubmitButton";
import MainContainer from "../../../components/Containers/MainContainer/MainContainer";
import MainForm from "../../../components/Forms/MainForm/MainForm";
import PasswordInput from "../../../components/Inputs/PasswordInput/PasswordInput";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { ADMIN_TOKEN_COOKIE } from "../../../constants/admin";
import { RootState } from "../../../store";
import { changePasswordAdmin, logoutAllAdmin } from "../../../store/admin/admin-actions";
import classes from './AdminEditAdmin.module.scss';


const AdminPage = () => {
  const dispatch = useDispatch();

  const adminData = useSelector((state: RootState) => state.admin.adminData);
  const { loading, error } = adminData;

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const newPasswordInputRef = useRef<HTMLInputElement>(null);
  const repeatNewPasswordInputRef = useRef<HTMLInputElement>(null);

  const submitHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const passwordValue = passwordInputRef.current!.value;
    const newPasswordValue = newPasswordInputRef.current!.value;
    const repeatNewPasswordValue = repeatNewPasswordInputRef.current!.value;

    dispatch(changePasswordAdmin(passwordValue, newPasswordValue, repeatNewPasswordValue, Cookies.get(ADMIN_TOKEN_COOKIE)!))
  }

  const logoutAllHandler = () => {
    dispatch(logoutAllAdmin(Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  const isPasswordValid = error.message ? false : true;

  return (
    <AdminLayout loading={loading} error={error} >
      <section className={classes.container}>
        <h1>Edycja danych Administratora</h1>

        <MainContainer>
          <div className={classes['logout-all-container']}>
            <h3>Wyloguj wszędzie</h3>
            <p>Wyloguj ze wszystkich urządzeń oraz sesji.</p>
            <p>Ze względów bezpieczeństwa NALEŻY to robić regularnie.</p>
          </div>
          <MainButton title="Wyloguj wszędzie" fontSize={1.2} onClick={logoutAllHandler} />
        </MainContainer>


        <div className={classes['edit-password-container']}>
          <MainForm onSubmit={submitHandler}>
            <h3>Zmiana hasła</h3>
            <div className={classes['edit-password-text-container']}>
              <p>Brak możliwości przypomnienia/zmienienia hasła za pomocą email.</p>
              <p>Proszę zapisać i zapamiętać NOWE HASŁO, ponieważ jego utrata wiąże się z utratą dostępu do panelu administratora</p>
            </div>
            <PasswordInput ref={passwordInputRef} isValid={true} isRequired={true} title={"Obecne hasło"} />
            <PasswordInput ref={newPasswordInputRef} isValid={isPasswordValid} isRequired={true} title={"Nowe hasło"} />
            <PasswordInput ref={repeatNewPasswordInputRef} isValid={isPasswordValid} isRequired={true} title={"Powtórz nowe hasło"} />
            {error.message && <p>{error.message}</p>}
            <SubmitButton title="Zmień hasło" fontSize={1.1} />
          </MainForm>
        </div>
      </section>
    </AdminLayout>
  );
};

export default AdminPage;
