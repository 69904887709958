import { createSlice } from "@reduxjs/toolkit";
import { DOGS_FAIL, DOGS_REQUEST, DOGS_SUCCESS } from "../../constants/dogs";

const dogsSlice = createSlice({
  name: 'dogs',
  initialState: {
    dogs: {
      loading: false,
      error: {
        message: ''
      },
      data: [{
        _id: '',
        name: '',
        description: '',
        imageUrl: '',
        galleryUrl: ''
      }]
    },
    dog: {
      loading: false,
      error: {
        message: ''
      },
      data: {
        _id: '',
        name: '',
        description: '',
        imageUrl: '',
        galleryUrl: ''
      }
    }
  },
  reducers: {
    dogsFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case DOGS_REQUEST:
          state.dogs.loading = true;
          state.dogs.error.message = '';
          break;
        case DOGS_SUCCESS:
          state.dogs.loading = false;
          state.dogs.data = payload;
          state.dogs.error.message = '';
          break;
        case DOGS_FAIL:
          state.dogs.loading = false;
          state.dogs.error = payload;
          break;
      }
    },
    singleDogFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case DOGS_REQUEST:
          state.dog.loading = true;
          state.dog.error.message = '';
          break;
        case DOGS_SUCCESS:
          state.dog.loading = false;
          state.dog.data = payload;
          state.dog.error.message = '';
          break;
        case DOGS_FAIL:
          state.dog.loading = false;
          state.dog.error = payload;
          break;
      }
    },
    editDogFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case DOGS_REQUEST:
          state.dog.loading = true;
          state.dog.error.message = '';
          break;
        case DOGS_SUCCESS:
          state.dog.loading = false;
          state.dog.data = payload;
          state.dog.error.message = '';
          break;
        case DOGS_FAIL:
          state.dog.loading = false;
          state.dog.error = payload;
          break;
      }
    },
    deleteDogController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case DOGS_REQUEST:
          state.dog.loading = true;
          state.dog.error.message = '';
          break;
        case DOGS_SUCCESS:
          state.dog.loading = false;
          state.dog.error.message = '';
          break;
        case DOGS_FAIL:
          state.dog.loading = false;
          state.dog.error = payload;
          break;
      }
    },
    addDogController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case DOGS_REQUEST:
          state.dog.loading = true;
          state.dog.error.message = '';
          break;
        case DOGS_SUCCESS:
          state.dog.loading = false;
          state.dog.error.message = '';
          break;
        case DOGS_FAIL:
          state.dog.loading = false;
          state.dog.error = payload;
          break;
      }
    }
  }
});

export const dogsActions = dogsSlice.actions;
export default dogsSlice;