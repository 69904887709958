import { useDispatch, useSelector } from 'react-redux';
import { navBarButtons } from '../../../constants/navBar';
import { RootState } from '../../../store';
import { uiActions } from '../../../store/ui/ui-slice';
import Backdrop from '../../Backdrop/Backdrop';
import HamburgerButton from '../../Buttons/HamburgerButton/HamburgerButton';
import NavButtonMobile from '../../Buttons/NavButtonMobile/NavButtonMobile';
import classes from './MobileMenu.module.scss';

const MobileMenu = () => {
  const dispatch = useDispatch();
  const isMobileMenuActive = useSelector((state: RootState) => state.ui.isMobileMenuActive);

  const renderNavButtons = navBarButtons.map(data => (
    <NavButtonMobile key={data.route} title={data.title} route={data.route} />
  ))

  const backdropClickHandler = () => {
    dispatch(uiActions.toggleMobileMenu());
  }

  return (
    <>
      <div className={classes.hamburger}>
        <HamburgerButton />
      </div>
      {isMobileMenuActive && 
        <>
          <div className={classes.menu}>
            {renderNavButtons}
          </div>
          <Backdrop onClick={backdropClickHandler} />
        </>
      }
    </>
  );
};

export default MobileMenu;
