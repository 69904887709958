
import Cookies from "js-cookie";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import BackButton from "../../../components/Buttons/BackButton/BackButton";
import MainButton from "../../../components/Buttons/MainButton/MainButton";
import SubmitButton from "../../../components/Buttons/SubmitButton/SubmitButton";
import AdminDogGalleryPicker from "../../../components/Containers/admin/AdminDogGalleryPicker/AdminDogGalleryPicker";
import ChangePhotoContainer from "../../../components/Containers/ChangePhotoContainer/ChangePhotoContainer";
import MainForm from "../../../components/Forms/MainForm/MainForm";
import FileInput from "../../../components/Inputs/FileInput/FileInput";
import TextAreaInput from "../../../components/Inputs/TextAreaInput/TextAreaInput";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import AdminLayout from "../../../components/Layout/AdminLayout/AdminLayout";
import { ADMIN_TOKEN_COOKIE } from "../../../constants/admin";
import { RootState } from "../../../store";
import { galleryFetch } from "../../../store/gallery/gallery-actions";
import { deletePuppy, editImagePuppy, editPuppy, puppyFetch } from "../../../store/puppies/puppies-actions";
import classes from './AdminEditPuppyPage.module.scss';

const AdminEditPuppyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isGalleryDisplay, setIsGalleryDisplay] = useState<boolean>(false);
  const [selectedGalleryId, setSelectedGalleryId] = useState<string>('');

  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  const puppy = useSelector((state: RootState) => state.puppies.puppy);
  const { loading, error, data } = puppy;

  const selectedGallery = useSelector((state: RootState) => state.gallery.gallery);
  const selectedGalleryName = selectedGallery.data.name;
  
  useEffect(() => {
    dispatch(puppyFetch(id!));
    window.scrollTo({ top: 400, behavior: 'smooth' });

    if (data.galleryUrl) {
      dispatch(galleryFetch(data.galleryUrl));
    }
    
  }, [dispatch, id, data.galleryUrl]);

  const submitEditDataHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const nameValue = nameInputRef.current!.value;
    const descriptionValue = descriptionInputRef.current!.value;
    const galleryUrlValue = selectedGalleryId;
    
    dispatch(editPuppy(data._id, nameValue, descriptionValue, galleryUrlValue, Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  const submitEditPhotoHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const fileData = fileInputRef.current!.files![0];
    dispatch(editImagePuppy(data._id, fileData, Cookies.get(ADMIN_TOKEN_COOKIE)!));
  }

  const submitDeletePhotoHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    dispatch(deletePuppy(data._id, Cookies.get(ADMIN_TOKEN_COOKIE)!));
    navigate(-1);
  }

  const toggleGalleryDisplay = () => {
    setIsGalleryDisplay(!isGalleryDisplay);
  }

  const setGalleryToDog = (galleryId: string) => {
    setSelectedGalleryId(galleryId);
    dispatch(galleryFetch(galleryId));
    
    toggleGalleryDisplay();
  }

  return (
    <AdminLayout loading={loading} error={error} >
      <div className={classes.container}>
        <h1>Edytuj "{ data.name }"</h1>
        <BackButton title="Wróć" fontSize={1.35} />

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitEditDataHandler}>
            <h3>Edytuj dane:</h3>

            <label>Nazwa:</label>
            <TextInput ref={nameInputRef} isValid={true} value={data.name} />
            <label>Opis:</label>
            <TextAreaInput ref={descriptionInputRef} value={data.description} />
            <label>Galeria:</label>
            {data.galleryUrl && <p>{selectedGalleryName}</p>}
            <MainButton title="Przypisz Galerię" fontSize={1.3} onClick={toggleGalleryDisplay} />
            
            {isGalleryDisplay &&
              <AdminDogGalleryPicker
                dogName={data.name}
                onClick={setGalleryToDog}
                closeModal={toggleGalleryDisplay}
              />
            }

            <SubmitButton title="ZAPISZ" fontSize={1.5} />
          </MainForm>
        </div>

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitEditPhotoHandler}>
            <h3>Edytuj zdjęcie:</h3>
            <div
              className={classes['form-image']}
            >
              <ChangePhotoContainer imageUrl={data.imageUrl} />
            </div>
            <div className={classes['file-picker-container']}>
              <FileInput ref={fileInputRef} isRequired={true} />
            </div>
            <SubmitButton title="ZAPISZ" fontSize={1.5} />
          </MainForm>
        </div>

        <div className={classes['form-container']}>
          <MainForm onSubmit={submitDeletePhotoHandler}>
            <h3>Usuń szczeniaczka</h3>
            <label>Kliknięcie przycisku spowoduje<br />trwałe usunięcie szczeniaczka</label>
            <SubmitButton title="USUŃ" fontSize={1.5} />
          </MainForm>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminEditPuppyPage;
