import Cookies from 'js-cookie';
import React, { SyntheticEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_TOKEN_COOKIE } from '../../../../constants/admin';
import { RootState } from '../../../../store';
import { addDog } from '../../../../store/dogs/dogs-actions';
import { galleryFetch } from '../../../../store/gallery/gallery-actions';
import MainButton from '../../../Buttons/MainButton/MainButton';
import SubmitButton from '../../../Buttons/SubmitButton/SubmitButton';
import MainForm from '../../../Forms/MainForm/MainForm';
import FileInput from '../../../Inputs/FileInput/FileInput';
import TextAreaInput from '../../../Inputs/TextAreaInput/TextAreaInput';
import TextInput from '../../../Inputs/TextInput/TextInput';
import AdminDogGalleryPicker from '../AdminDogGalleryPicker/AdminDogGalleryPicker';
import classes from './AdminAddDog.module.scss';

const AdminAddDog = () => {
  const dispatch = useDispatch();

  const [isGalleryDisplay, setIsGalleryDisplay] = useState<boolean>(false);
  const [selectedGalleryId, setSelectedGalleryId] = useState<string>('');

  const selectedGallery = useSelector((state: RootState) => state.gallery.gallery);
  const selectedGalleryName = selectedGallery.data.name;

  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const submitAddDog = (event: SyntheticEvent) => {
    event.preventDefault();

    const nameValue = nameInputRef.current!.value;
    const descriptionValue = descriptionInputRef.current!.value;
    const galleryUrlValue = selectedGalleryId;
    const fileData = fileInputRef.current!.files![0];

    dispatch(addDog(nameValue, descriptionValue, galleryUrlValue, fileData, Cookies.get(ADMIN_TOKEN_COOKIE)!));
    window.scrollTo({ top: document.body.clientHeight, behavior: 'smooth' })
  }

  const toggleGalleryDisplay = () => {
    setIsGalleryDisplay(!isGalleryDisplay);
  }

  const setGalleryToDog = (galleryId: string) => {
    setSelectedGalleryId(galleryId);
    dispatch(galleryFetch(galleryId));
    
    toggleGalleryDisplay();
  }

  return (
    <div className={classes['form-container']}>
      <MainForm onSubmit={submitAddDog}>

        <label>Nazwa:</label>
        <TextInput ref={nameInputRef} isValid={true} value={''} />
        <label>Opis:</label>
        <TextAreaInput ref={descriptionInputRef} value={''} />
        <label>Galeria:</label>
        {selectedGalleryId && <p>{selectedGalleryName}</p>}
        <MainButton title="Przypisz Galerię" fontSize={1.3} onClick={toggleGalleryDisplay} />
          
        {isGalleryDisplay &&
          <AdminDogGalleryPicker
            dogName='nowej suki'
            onClick={setGalleryToDog}
            closeModal={toggleGalleryDisplay}
          />
        }

        <label>Zdjęcie:</label>
        <FileInput ref={fileInputRef} isRequired={true} />
        
        <SubmitButton title="DODAJ" fontSize={1.5} />
      </MainForm>
    </div>
  );
};

export default AdminAddDog;
