import { fullscreenImageActions } from "./fullscreenImage-slice"

export const toggleFullscreenImage = (images?: Array<string>, actualImage?: string) => (dispatch: any) => {
  dispatch(fullscreenImageActions.toggleFullscreenImageActive());
  
  if (images && actualImage) {
    dispatch(fullscreenImageActions.setImages(images))
    dispatch(fullscreenImageActions.setActualImage(actualImage));
  }
}

export const changeImage = (images: Array<string>, actualImage: string, isIncreasing: boolean) => (dispatch: any) => {
  let imageToDisplay = actualImage;

  images.forEach((image, index) => {
    if(image === actualImage) {
      if(isIncreasing) {
        if(index + 1 === images.length) {
          imageToDisplay = images[0];
          return;
        }
        imageToDisplay = images[index + 1];
      } else {
        if(index - 1 < 0) {
          imageToDisplay = images[images.length -1];
          return;
        }
        imageToDisplay = images[index - 1];
      }
    }
  });

  if (imageToDisplay !== actualImage) {
    dispatch(fullscreenImageActions.setActualImage(imageToDisplay));
  }
}