import { createSlice } from "@reduxjs/toolkit";
import { ABOUT_FAIL, ABOUT_REQUEST, ABOUT_SUCCESS } from "../../constants/about";

const aboutSlice = createSlice({
  name: 'about',
  initialState: {
    about: {
      loading: false,
      error: {
        message: ''
      },
      data: {
        _id: '',
        title: '',
        text: '',
        imageUrl: ''
      }
    }
  },
  reducers: {
    aboutFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case ABOUT_REQUEST:
          state.about.loading = true;
          state.about.error.message = '';
          break;
        case ABOUT_SUCCESS:
          state.about.loading = false;
          state.about.data = payload;
          state.about.error.message = '';
          break;
        case ABOUT_FAIL:
          state.about.loading = false;
          state.about.error = payload;
          break;
      }
    },
    editAboutController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case ABOUT_REQUEST:
          state.about.loading = true;
          state.about.error.message = '';
          break;
        case ABOUT_SUCCESS:
          state.about.loading = false;
          state.about.data = payload;
          state.about.error.message = '';
          break;
        case ABOUT_FAIL:
          state.about.loading = false;
          state.about.error = payload;
          break;
      }
    }
  }
});

export const aboutActions = aboutSlice.actions;
export default aboutSlice;