import { Link } from 'react-router-dom';
import classes from './LinkButton.module.scss';

interface IProps {
  title: string;
  to: string;
  fontSize: number;
  onClick?: () => any;
}

const LinkButton = ({title, to, fontSize, onClick}: IProps) => {
  return (
    <Link
      to={to}
      onClick={onClick}
      className={classes['main-button']}
      style={
        {fontSize: `${fontSize}rem`}
      }
    >
      {title}
    </Link>
  );
};

export default LinkButton;
