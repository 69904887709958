import classes from './AdminGalleryPickerGalleryContainer.module.scss';
import dogLogoImage from '../../../../images/logo/dogsLogo.png';
import notImage from '../../../../images/icons/notIcon.svg';
import { IGalleries } from '../../../../interfaces/IGalleries';
import { BACKEND_URL } from '../../../../config/backendData';

interface IProps extends IGalleries {
  onClick: () => any
}

const AdminGalleryPickerGalleryContainer = ({ _id, name, images, onClick }: IProps) => {

  //const backgroundUrl = images[0] ? `${BACKEND_URL}/${images[0]}` : dogLogoImage
  let backgroundUrl;

  if (images[0]) {
    backgroundUrl = `${BACKEND_URL}/${images[0]}`;
  } else if (_id === '') {
    backgroundUrl = notImage;
  } else {
    backgroundUrl = dogLogoImage
  }

  return (
    <div className={classes['gallery-container']} onClick={onClick}>
      <div className={classes['gallery-image']} style={{ backgroundImage: `url('${backgroundUrl}')` }} />
      <h3>{name}</h3>
    </div>
  );
};

export default AdminGalleryPickerGalleryContainer;
