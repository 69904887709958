import { createSlice } from "@reduxjs/toolkit";

const fullscreenImageSlice = createSlice({
  name: 'fulscreenImage',
  initialState: {
    isActive: false,
    images: [],
    actualImage: ''
  },
  reducers: {
    toggleFullscreenImageActive(state) {
      state.isActive = !state.isActive;

      if (!state.isActive) {
        state.images = [];
        state.actualImage = '';
      }
    },
    setImages(state, action) {
      state.images = action.payload;
    },
    setActualImage(state, action) {
      state.actualImage = action.payload;
    }
  }
});

export const fullscreenImageActions = fullscreenImageSlice.actions;
export default fullscreenImageSlice;