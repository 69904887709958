import { createSlice } from "@reduxjs/toolkit";
import { GALLERY_FAIL, GALLERY_REQUEST, GALLERY_SUCCESS } from "../../constants/gallery";

const gallerySlice = createSlice({
  name: 'gallery',
  initialState: {
    gallery: {
      loading: false,
      error: {
        message: ''
      },
      data: {
        _id: '',
        name: '',
        images: []
      }
    }
  },
  reducers: {
    galleryFetchController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case GALLERY_REQUEST:
          state.gallery.loading = true;
          state.gallery.error.message = '';
          state.gallery.data._id = '';
          state.gallery.data.name = '';
          state.gallery.data.images = [];
          break;
        case GALLERY_SUCCESS:
          state.gallery.loading = false;
          state.gallery.data = payload;
          state.gallery.error.message = '';
          break;
        case GALLERY_FAIL:
          state.gallery.loading = false;
          state.gallery.error = payload;
          break;
      }
    },
    editGalleryController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case GALLERY_REQUEST:
          state.gallery.loading = true;
          state.gallery.data._id = '';
          state.gallery.data.name = '';
          state.gallery.error.message = '';
          state.gallery.data.images = [];
          break;
        case GALLERY_SUCCESS:
          state.gallery.loading = false;
          state.gallery.data = payload;
          state.gallery.error.message = '';
          break;
        case GALLERY_FAIL:
          state.gallery.loading = false;
          state.gallery.error = payload;
          break;
      }
    },
    addGalleryController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case GALLERY_REQUEST:
          state.gallery.loading = true;
          state.gallery.error.message = '';
          state.gallery.data._id = '';
          state.gallery.data.name = '';
          state.gallery.data.images = [];
          break;
        case GALLERY_SUCCESS:
          state.gallery.loading = false;
          state.gallery.data = payload;
          state.gallery.error.message = '';
          break;
        case GALLERY_FAIL:
          state.gallery.loading = false;
          state.gallery.error = payload;
          break;
      }
    },
    deleteGalleryController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case GALLERY_REQUEST:
          state.gallery.loading = true;
          state.gallery.data._id = '';
          state.gallery.data.name = '';
          state.gallery.data.images = [];
          state.gallery.error.message = '';
          break;
        case GALLERY_SUCCESS:
          state.gallery.loading = false;
          state.gallery.error.message = '';
          break;
        case GALLERY_FAIL:
          state.gallery.loading = false;
          state.gallery.error = payload;
          break;
      }
    },
    deleteImageGalleryController(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case GALLERY_REQUEST:
          state.gallery.loading = true;
          state.gallery.data._id = '';
          state.gallery.data.name = '';
          state.gallery.data.images = [];
          state.gallery.error.message = '';
          break;
        case GALLERY_SUCCESS:
          state.gallery.loading = false;
          state.gallery.data = payload;
          state.gallery.error.message = '';
          break;
        case GALLERY_FAIL:
          state.gallery.loading = false;
          state.gallery.error = payload;
          break;
      }
    }
  }
});

export const galleryActions = gallerySlice.actions;
export default gallerySlice;