import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dog from '../../components/Dog/Dog';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { RootState } from '../../store';
import { dogsFetch } from '../../store/dogs/dogs-actions';
import classes from './DogsPage.module.scss';

const DogsPage = () => {
  const dispatch = useDispatch();

  const isMobile = useSelector((state: RootState) => state.userWindow.isMobile);
  const dogs = useSelector((state: RootState) => state.dogs.dogs);
  const { loading, error, data } = dogs;

  useEffect(() => {
    dispatch(dogsFetch());
    
  }, [dispatch]);
  

  const renderDogs = data.map((dog) => (
    <Dog
      key={dog._id}
      _id={dog._id}
      name={dog.name}
      description={dog.description}
      imageUrl={dog.imageUrl}
      galleryUrl={dog.galleryUrl} />
  ))
  
  return (
    <MainLayout>
      <section className={classes['dog-page-container']}>
        {isMobile && <h1 className={classes['dogs-title']}>Nasze suki</h1>}
        { loading && 
        <div className={classes['status-container']}>
          <LoadingSpinner />
        </div>
        }

        { error.message &&
        <div className={classes['status-container']}>
          <ErrorBox errorMessage={error.message} />
        </div>
        }

        {data[0] &&
        <>
          {renderDogs}  
        </>
        }

      </section>
    </MainLayout>
  );
};

export default DogsPage;
