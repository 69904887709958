import { Link } from 'react-router-dom';
import { BACKEND_URL } from '../../config/backendData';
import { IGalleries } from '../../interfaces/IGalleries';
import classes from './GalleryContainer.module.scss';

import dogLogoImage from '../../images/mainPage/dogLogo.png'
import EditIcon from '../EditIcon/EditIcon';

interface IProps extends IGalleries {
  isAdminPanel?: boolean;
}


const GalleryContainer = ({ _id, name, images, isAdminPanel }: IProps) => {
  const galleryUrl = `${_id}`;

  const backgroundUrl = images[0] ? `${BACKEND_URL}/${images[0]}` : dogLogoImage;

  return (
    <Link to={galleryUrl} className={classes['gallery-container']}>
      <div className={classes['gallery-image']} style={{ backgroundImage: `url('${backgroundUrl}')` }} >
        {isAdminPanel && <EditIcon />}
      </div>
      <h3>{name}</h3>
    </Link>
  );
};

export default GalleryContainer;
