import { useNavigate } from 'react-router';
import { BACKEND_URL } from '../../../../config/backendData';
import { IDog } from '../../../../interfaces/IDog';
import EditIcon from '../../../EditIcon/EditIcon';
import classes from './AdminDogContainer.module.scss';


const AdminDogContainer = ({ _id, name, imageUrl }: IDog) => {
  const navigate = useNavigate();

  const backroundImageUrl = imageUrl ? `${BACKEND_URL}/${imageUrl}` : '';
  
  const clickHandler = () => {
    navigate(`/admin/nasze-suki/${_id}`);
  }
  
  return (
    <button className={classes['dog-container']} onClick={clickHandler}>
      <div className={classes['dog-image']} style={{ backgroundImage: `url('${backroundImageUrl}')` }} >
        <EditIcon />
      </div>
      <h3 className={classes['dog-name']}>{name}</h3>
      <p>(kliknij okno aby edytować)</p>
    </button>
  );
};

export default AdminDogContainer;
