import { Route, Routes } from "react-router";
import AdminAbout from "./pages/Admin/AdminAbout/AdminAbout";
import AdminEditContact from "./pages/Admin/AdminContact/AdminEditContact";
import AdminEditAdmin from "./pages/Admin/AdminEditAdmin/AdminEditAdminPage";
import AdminGalleryEditPage from "./pages/Admin/AdminGallery/AdminGalleryEditPage";
import AdminGalleryPage from "./pages/Admin/AdminGallery/AdminGalleryPage";
import AdminLoginPage from "./pages/Admin/AdminLoginPage/AdminLoginPage";
import AdminEditOurDogsPage from "./pages/Admin/AdminOurDogs/AdminEditOurDogsPage";
import AdminOurDogsPage from "./pages/Admin/AdminOurDogs/AdminOurDogsPage";
import AdminPage from "./pages/Admin/AdminPage/AdminPage";
import AdminEditPuppyPage from "./pages/Admin/AdminPuppies/AdminEditPuppyPage";
import AdminPuppiesPage from "./pages/Admin/AdminPuppies/AdminPuppiesPage";
import AdminEditRecommendPage from "./pages/Admin/AdminRecommend/AdminEditRecommendPage";
import AdminRecommendPage from "./pages/Admin/AdminRecommend/AdminRecommendPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import DogsPage from "./pages/DogsPage/DogsPage";
import GalleriesPage from "./pages/GalleriesPage/GalleriesPage";
import GalleryPage from "./pages/GalleryPage/GalleryPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import PuppiesPage from "./pages/PuppiesPage/PuppiesPage";
import RecommendPage from "./pages/RecommendPage/RecommendPage";


const App = () => {
  return (
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/kontakt' element={<ContactPage />} />
      <Route path='/nasze-suki' element={<DogsPage />} />
      <Route path='/szczenieta' element={<PuppiesPage />} />
      <Route path='/polecamy' element={<RecommendPage />} />
      <Route path='/galeria' element={<GalleriesPage />} />
      <Route path='/galeria/:id' element={<GalleryPage />} />
      <Route path='/admin' element={<AdminPage />} />
      <Route path='/admin/login' element={<AdminLoginPage />} />
      <Route path='/admin/edit-admin/:id' element={<AdminEditAdmin />} />
      <Route path='/admin/polecamy' element={<AdminRecommendPage />} />
      <Route path='/admin/polecamy/:id' element={<AdminEditRecommendPage />} />
      <Route path='/admin/nasze-suki' element={<AdminOurDogsPage />} />
      <Route path='/admin/nasze-suki/:id' element={<AdminEditOurDogsPage />} />
      <Route path='/admin/szczenieta' element={<AdminPuppiesPage />} />
      <Route path='/admin/szczenieta/:id' element={<AdminEditPuppyPage />} />
      <Route path='/admin/galeria' element={<AdminGalleryPage />} />
      <Route path='/admin/galeria/:id' element={<AdminGalleryEditPage />} />
      <Route path='/admin/kontakt' element={<AdminEditContact />} />
      <Route path='/admin/o-nas' element={<AdminAbout />} />
    </Routes>
  );
}

export default App;
