import Cookies from 'js-cookie';
import { SyntheticEvent, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ADMIN_TOKEN_COOKIE } from '../../../../constants/admin';
import { addRecommend } from '../../../../store/recommend/recommend-actions';
import SubmitButton from '../../../Buttons/SubmitButton/SubmitButton';
import MainForm from '../../../Forms/MainForm/MainForm';
import FileInput from '../../../Inputs/FileInput/FileInput';
import TextInput from '../../../Inputs/TextInput/TextInput';
import classes from './AdminAddRecommendation.module.scss';

const AdminAddRecommendation = () => {
  const dispatch = useDispatch();

  const nameAddInputRef = useRef<HTMLInputElement>(null);
  const linkAddInputRef = useRef<HTMLInputElement>(null);
  const fileAddInputRef = useRef<HTMLInputElement>(null);

  const subminAddRecommendHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const nameValue = nameAddInputRef.current!.value;
    const linkValue = linkAddInputRef.current!.value;
    const fileData = fileAddInputRef.current!.files![0];

    dispatch(addRecommend(nameValue, linkValue, fileData, Cookies.get(ADMIN_TOKEN_COOKIE)!));
    window.scrollTo({ top: document.body.clientHeight, behavior: 'smooth' })
  }

  return (
    <div className={classes['form-container']}>
      <MainForm onSubmit={subminAddRecommendHandler}>
        <h3>Dodaj nowe polecane</h3>
        <label>Nazwa:</label>
        <TextInput ref={nameAddInputRef} isValid={true} value='Nazwa' />
        <label>Link:</label>
        <TextInput ref={linkAddInputRef} isValid={true} value='' />
        <label>Zdjęcie:</label>
        <FileInput ref={fileAddInputRef} isRequired={true} />
        <SubmitButton title="DODAJ" fontSize={1.5} />
      </MainForm>
    </div>
  )
};

export default AdminAddRecommendation;
