import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isMobileMenuActive: false
  },
  reducers: {
    toggleMobileMenu(state) {
      state.isMobileMenuActive = !state.isMobileMenuActive;
    }
  }
});

export const uiActions = uiSlice.actions;
export default uiSlice;