import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import MainButton from '../../components/Buttons/MainButton/MainButton';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import FullscreenImageContainer from '../../components/FullscreenImageContainer/FullscreenImageContainer';
import GalleryImage from '../../components/GalleryImage/GalleryImage';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { RootState } from '../../store';
import { toggleFullscreenImage } from '../../store/fullscreenImage/fullscreenImage-actions';
import { galleryFetch } from '../../store/gallery/gallery-actions';
import classes from './/GalleryPage.module.scss';

const GalleryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id: galleryId } = params;
  
  const isMobile = useSelector((state: RootState) => state.userWindow.isMobile);
  const gallery = useSelector((state: RootState) => state.gallery.gallery);
  const { loading, error, data } = gallery;
  
  
  useEffect(() => {
    dispatch(galleryFetch(galleryId!));
    
  }, [dispatch, galleryId]);

  const redirectBack = () => {
    navigate(-1)
  }

  const toggleFullscreenImageHandler = (actualImage: string) => {
    dispatch(toggleFullscreenImage(data.images, actualImage)); 
  }

  const renderImages = data.images.map(image => (
    <GalleryImage key={image} image={image} onClick={toggleFullscreenImageHandler.bind(this, image)} />
  ))


  return (
    <MainLayout>
      <section className={classes['gallery-container']}>
        {isMobile && 
          <div className={classes['gallery-title-container']}>
            <h1 className={classes['gallery-title']}>Galeria</h1>
            <h2 className={classes['gallery-subtitle']}>- {data.name} -</h2>
          </div>
        }
        { loading && 
        <div className={classes['status-container']}>
          <LoadingSpinner />
        </div>
        }

        { error.message &&
        <div className={classes['status-container']}>
          <ErrorBox errorMessage={error.message} />
        </div>
        }

        <MainButton title='Powrót' fontSize={2} onClick={redirectBack} />

        { data.images[0] &&
          <div className={classes['images-container']}>
            {renderImages}
          </div>
        }

        <FullscreenImageContainer />
      </section>
    </MainLayout>
  );
};

export default GalleryPage;
