import { createSlice } from "@reduxjs/toolkit";

const userWindow = createSlice({
  name: 'userWindow',
  initialState: {
    isWindowScrolled: false,
    isMobile: false
  },
  reducers: {
    setScrollStatus(state, action: { payload: boolean }) {
      state.isWindowScrolled = action.payload
    },
    setDevice(state, action: { payload: boolean }) {
      state.isMobile = action.payload
    }
  }
})

export const userWindowActions = userWindow.actions;
export default userWindow;