import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { toggleFullscreenImage } from '../../store/fullscreenImage/fullscreenImage-actions';
import Backdrop from '../Backdrop/Backdrop';
import FullscreenImage from '../FullscreenImage/FullscreenImage';
import classes from './FullscreenImageContainer.module.scss';


const FullscreenImageContainer = () => {
  const isFullscreenImageActive = useSelector((state: RootState) => state.fullscreenImage.isActive);

  const dispatch = useDispatch();

  const toggleFullscreenImageHandler = () => {
    dispatch(toggleFullscreenImage());
  }

  return (
    <>
      {isFullscreenImageActive &&
        ReactDOM.createPortal(
          <>
            <Backdrop onClick={toggleFullscreenImageHandler} />
            <div className={classes.container}>
              <FullscreenImage />
            </div>
          </>
    
          ,document.getElementById('fullscreen-image')!
        )
      }
    </>
  );
};

export default FullscreenImageContainer;
