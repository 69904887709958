import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setActualDevice, setScrollStatus } from '../../../store/window/userWindow-actions';
import CookiesInfo from '../../CookiesInfo/CookiesInfo';
import Footer from '../../Ui/Footer/Footer';
import Header from '../../Ui/Header/Header';
import MobileMenu from '../../Ui/MobileMenu/MobileMenu';
import NavBar from '../../Ui/NavBar/NavBar';
import classes from './MainLayout.module.scss';

interface IProps {
  children: React.ReactChild;
}

const MainLayout = ({ children }: IProps) => {
  
  const dispatch = useDispatch();

  const isWindowScrolled = useSelector((state: RootState) => state.userWindow.isWindowScrolled);
  const isMobile = useSelector((state: RootState) => state.userWindow.isMobile);

  const [isCookiesInfo, setIsCookiesInfo] = useState<boolean>(Cookies.get('cookies') === 'true' ? false : true);

  const scrollHandler = () => {
    dispatch(setScrollStatus(window.scrollY));
  }
  window.addEventListener('scroll', scrollHandler);

  const setDisplayContent = () => {
    dispatch(setActualDevice(window.innerWidth));
  }
  window.addEventListener('resize', setDisplayContent);
  setDisplayContent();

  const mainClass = isWindowScrolled && !isMobile
    ? `${classes['main-container--active']} ${classes['main-container']}`
    : classes['main-container']

  const closeCookiesWindowHandler = () => {
    Cookies.set('cookies', 'true', { expires: 180 });
    setIsCookiesInfo(Cookies.get('cookies') === 'true' ? false : true);
  }
  
  return (
    <>
      {isCookiesInfo && <CookiesInfo onClick={closeCookiesWindowHandler} />} 
      <Header />
      {!isMobile && <NavBar />}
      {isMobile && <MobileMenu />}
      <main className={mainClass}>
        {children}
      </main>
      <Footer />
    </>
  );
};

export default MainLayout;
