import { SyntheticEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import SubmitButton from '../../../components/Buttons/SubmitButton/SubmitButton';
import MainForm from '../../../components/Forms/MainForm/MainForm';
import EmailInput from '../../../components/Inputs/EmailInput/EmailInput';
import PasswordInput from '../../../components/Inputs/PasswordInput/PasswordInput';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Footer from '../../../components/Ui/Footer/Footer';
import Header from '../../../components/Ui/Header/Header';
import { RootState } from '../../../store';
import classes from './AdminLoginPage.module.scss';
import { loginAdmin } from '../../../store/admin/admin-actions';

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminData = useSelector((state: RootState) => state.admin.adminData);
  const {token , loading, error} = adminData


  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (token) {
      navigate('/admin')
    }
  }, [navigate, token]);

  const onSubmitHandler = (event: SyntheticEvent) => {
    event.preventDefault();

    const emailValue = emailInputRef.current!.value;
    const passwordValue = passwordInputRef.current!.value;
    
    dispatch(loginAdmin(emailValue, passwordValue));
  }


  return (
    <main className={classes.container}>

      <div className={classes['header-container']}>
        <Header />
      </div>

      <div className={classes['form-container']}>
        <MainForm onSubmit={onSubmitHandler}>
          {!loading ?
            <>
              <h1>Logowanie</h1>
              <EmailInput ref={emailInputRef} isRequired={true} title='email' />
              <PasswordInput ref={passwordInputRef} isValid={true} isRequired={true} title='hasło' />
              {error.message && <p>Hasło lub email nieprawidłowe.</p>}
              <SubmitButton title='Zaloguj się' fontSize={1.2} />
            </>
            :
            <div className={classes.spinner}>
              <LoadingSpinner />
            </div>
          }
        </MainForm>
      </div>

      <div className={classes['footer-container']}>
        <Footer />
      </div>
      
    </main>
  );
};

export default AdminLoginPage;
