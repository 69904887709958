import { configureStore } from "@reduxjs/toolkit";
import aboutSlice from "./about/about-slice";
import adminSlice from "./admin/admin-slice";
import contactSlice from "./contact/contact-slice";
import dogsSlice from "./dogs/dogs-slice";
import fullscreenImageSlice from "./fullscreenImage/fullscreenImage-slice";
import galleriesSlice from "./galleries/galleries-slice";
import gallerySlice from "./gallery/gallery-slice";
import puppiesSlice from "./puppies/puppies-slice";
import recommendSlice from "./recommend/recommend-slice";
import uiSlice from "./ui/ui-slice";
import userWindowSlice from "./window/userWindow-slice";

const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    userWindow: userWindowSlice.reducer,
    contact: contactSlice.reducer,
    dogs: dogsSlice.reducer,
    puppies: puppiesSlice.reducer,
    recommend: recommendSlice.reducer,
    galleries: galleriesSlice.reducer,
    gallery: gallerySlice.reducer,
    fullscreenImage: fullscreenImageSlice.reducer,
    admin: adminSlice.reducer,
    about: aboutSlice.reducer
  }
});

export default store;
export type RootState = ReturnType<typeof store.getState>;