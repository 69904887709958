import axios from "axios";
import { BACKEND_URL } from "../../config/backendData";
import { GALLERY_FAIL, GALLERY_REQUEST, GALLERY_SUCCESS } from "../../constants/gallery";
import { galleryActions } from "./gallery-slice";

export const galleryFetch = (galleryId: string) => async (dispatch: any) => {
  dispatch(galleryActions.galleryFetchController({
    type: GALLERY_REQUEST
  }));

  const getGallery = async () => {
    const data = await axios.get(`${BACKEND_URL}/gallery/${galleryId}`);
    return data;
  }

  try {
    const { data } = await getGallery();
    
    dispatch(galleryActions.galleryFetchController({
      type: GALLERY_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(galleryActions.galleryFetchController({
      type: GALLERY_FAIL,
      payload: {message: 'Pobieranie galerii nie powiodło się.'}
    }))
  }
}

//EDIT gallery data
export const editGallery = (_id: string, name: string, token: string) => async (dispatch: any) => {
  dispatch(galleryActions.editGalleryController({
    type: GALLERY_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const editedGallery = {
    name
  }

  const editGallery = async () => {
    const data = await axios.patch(`${BACKEND_URL}/gallery/data/${_id}`, editedGallery, config);
    return data;
  }

  try {
    const { data } = await editGallery();
    
    dispatch(galleryActions.editGalleryController({
      type: GALLERY_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(galleryActions.editGalleryController({
      type: GALLERY_FAIL,
      payload: {message: 'Edycja galerii nie powiodła się.'}
    }))
  }
}

//ADD gallery Image
export const addImagesToGallery = (_id: string, images: FileList, token: string) => async (dispatch: any) => {
  dispatch(galleryActions.addGalleryController({
    type: GALLERY_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const formData = new FormData();
  //formData.append('images', images, image.name);
  // images.map(image => {
  //   formData.append('images', image, image.name);
  // })
  for (let i = 0; i < images.length; i++) {
    formData.append('images', images[i], images[i].name);
  }

  const addGalleryImage = async () => {
    const data = await axios.post(`${BACKEND_URL}/gallery/images/${_id}`, formData, config);
    return data;
  }

  try {
    const { data } = await addGalleryImage();
    
    dispatch(galleryActions.addGalleryController({
      type: GALLERY_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(galleryActions.addGalleryController({
      type: GALLERY_FAIL,
      payload: {message: 'Dodanie zdjęć nie powiodło się.'}
    }))
  }
}

//Delete gallery image
export const deleteImageGallery = (_id: string, imageUrl: string, token: string) => async (dispatch: any) => {
  dispatch(galleryActions.deleteImageGalleryController({
    type: GALLERY_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const imageToDelete = {
    imageUrl
  }

  const deleteImage = async () => {
    const data = await axios.patch(`${BACKEND_URL}/gallery/images/${_id}`, imageToDelete, config);
    return data;
  }

  try {
    const { data } = await deleteImage();
    
    dispatch(galleryActions.deleteImageGalleryController({
      type: GALLERY_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(galleryActions.deleteImageGalleryController({
      type: GALLERY_FAIL,
      payload: {message: 'Usunięcie zdjęcia nie powiodła się.'}
    }))
  }
}

//DELETE gallery
export const deleteGallery = (_id: string, token: string) => async (dispatch: any) => {
  dispatch(galleryActions.deleteGalleryController({
    type: GALLERY_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const deleteSingleGalley = async () => {
    const data = await axios.delete(`${BACKEND_URL}/gallery/${_id}`, config);
    return data;
  }

  try {
    await deleteSingleGalley();
    
    dispatch(galleryActions.deleteGalleryController({
      type: GALLERY_SUCCESS
    }));
    
  } catch (error) {
    dispatch(galleryActions.deleteGalleryController({
      type: GALLERY_FAIL,
      payload: {message: 'Usunięcie galerii nie powiodło się.'}
    }))
  }
}