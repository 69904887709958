import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dog from '../../components/Dog/Dog';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { RootState } from '../../store';
import { puppiesFetch } from '../../store/puppies/puppies-actions';
import classes from './PuppiesPage.module.scss';

const PuppiesPage = () => {
  const dispatch = useDispatch();

  const isMobile = useSelector((state: RootState) => state.userWindow.isMobile);
  const puppies = useSelector((state: RootState) => state.puppies.puppies);
  const { loading, error, data } = puppies;

  useEffect(() => {
    dispatch(puppiesFetch());
    
  }, [dispatch]);
  

  const renderPuppies = data.map((puppy) => (
    <Dog
      key={puppy._id}
      _id={puppy._id}
      name={puppy.name}
      description={puppy.description}
      imageUrl={puppy.imageUrl}
      galleryUrl={puppy.galleryUrl} />
  ))
  
  return (
    <MainLayout>
      <section className={classes['puppy-page-container']}>
        {isMobile && <h1 className={classes['puppies-title']}>Nasze szczenięta</h1>}
        { loading && 
        <div className={classes['status-container']}>
          <LoadingSpinner />
        </div>
        }

        { error.message &&
        <div className={classes['status-container']}>
          <ErrorBox errorMessage={error.message} />
        </div>
        }

        {data[0] &&
        <>
          {renderPuppies}  
        </>
        }

      </section>
    </MainLayout>
  );
};

export default PuppiesPage;
