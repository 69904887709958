import axios from "axios";
import { BACKEND_URL } from "../../config/backendData";
import { DOGS_FAIL, DOGS_REQUEST, DOGS_SUCCESS } from "../../constants/dogs";
import { dogsActions } from "./dogs-slice";
//GET all dogs
export const dogsFetch = () => async (dispatch: any) => {
  dispatch(dogsActions.dogsFetchController({
    type: DOGS_REQUEST
  }));

  const getDogs = async () => {
    const data = await axios.get(`${BACKEND_URL}/dog`);
    return data;
  }

  try {
    const { data } = await getDogs();
    
    dispatch(dogsActions.dogsFetchController({
      type: DOGS_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(dogsActions.dogsFetchController({
      type: DOGS_FAIL,
      payload: {message: 'Pobieranie suk nie powiodło się.'}
    }))
  }
}

//GET single dog
export const dogFetch = (_id: string) => async (dispatch: any) => {
  dispatch(dogsActions.singleDogFetchController({
    type: DOGS_REQUEST
  }));

  const getSingleDog = async () => {
    const data = await axios.get(`${BACKEND_URL}/dog/${_id}`);
    return data;
  }

  try {
    const { data } = await getSingleDog();
    
    dispatch(dogsActions.singleDogFetchController({
      type: DOGS_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(dogsActions.singleDogFetchController({
      type: DOGS_FAIL,
      payload: {message: 'Pobieranie suki nie powiodło się.'}
    }))
  }
}

//EDIT dog data
export const editDog = (_id: string, name: string, description: string, galleryUrl: string, token: string) => async (dispatch: any) => {
  dispatch(dogsActions.editDogFetchController({
    type: DOGS_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const editedDog = {
    name,
    description,
    galleryUrl
  }

  const editDog = async () => {
    const data = await axios.patch(`${BACKEND_URL}/dog/data/${_id}`, editedDog, config);
    return data;
  }

  try {
    const { data } = await editDog();
    
    dispatch(dogsActions.editDogFetchController({
      type: DOGS_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(dogsActions.editDogFetchController({
      type: DOGS_FAIL,
      payload: {message: 'Edycja suki nie powiodła się.'}
    }))
  }
}

//EDIT dog Image
export const editImageDog = (_id: string, image: File, token: string) => async (dispatch: any) => {
  dispatch(dogsActions.editDogFetchController({
    type: DOGS_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const formData = new FormData();
  formData.append('image', image, image.name);

  const editDogImage = async () => {
    const data = await axios.patch(`${BACKEND_URL}/dog/image/${_id}`, formData, config);
    return data;
  }

  try {
    const { data } = await editDogImage();
    
    dispatch(dogsActions.editDogFetchController({
      type: DOGS_SUCCESS,
      payload: data
    }))
    
  } catch (error) {
    dispatch(dogsActions.editDogFetchController({
      type: DOGS_FAIL,
      payload: {message: 'Edycja suki nie powiodło się.'}
    }))
  }
}

//DELETE dog
export const deleteDog = (_id: string, token: string) => async (dispatch: any) => {
  dispatch(dogsActions.deleteDogController({
    type: DOGS_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const deleteSingleDog = async () => {
    const data = await axios.delete(`${BACKEND_URL}/dog/${_id}`, config);
    return data;
  }

  try {
    await deleteSingleDog();
    
    dispatch(dogsActions.deleteDogController({
      type: DOGS_SUCCESS
    }));
    
  } catch (error) {
    dispatch(dogsActions.deleteDogController({
      type: DOGS_FAIL,
      payload: {message: 'Usunięcie suki nie powiodło się.'}
    }))
  }
}

//ADD dog
export const addDog = (name: string, description: string, galleryUrl: string, image: File, token: string) => async (dispatch: any) => {
  dispatch(dogsActions.addDogController({
    type: DOGS_REQUEST
  }));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const formData = new FormData();
  formData.append('image', image, image.name);
  formData.append('name', name);
  formData.append('description', description);
  formData.append('galleryUrl', galleryUrl);

  const addDog = async () => {
    const data = await axios.post(`${BACKEND_URL}/dog`, formData, config);
    return data;
  }

  try {
    await addDog();
    
    dispatch(dogsActions.addDogController({
      type: DOGS_SUCCESS
    }))
    dispatch(dogsFetch());
  } catch (error) {
    dispatch(dogsActions.addDogController({
      type: DOGS_FAIL,
      payload: {message: 'Dodanie suki nie powiodło się.'}
    }))
  }
}